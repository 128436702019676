import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, AppBar, Toolbar } from '@material-ui/core';
import * as FORMS from '../constants/forms';
// TODO remove redux dependency
import { openModal, closeModal } from '../actions/common.actions';
import { submitContactForm, verifyAndSubmitContactForm } from '../actions/contactForm.actions';
import { getMyUserAccountData } from '../actions/userAccess.actions';
import { contactActions } from '../views/Contacts/contacts.ducks';

const PanelFooter = (props) => {
  const { form } = props;
  let buttonDisabled = !props.isFormValid || !props.isFormDirty;
  // if the contact is unverified we want to bypass isFormDirty
  if (props.isUnverified) {
    buttonDisabled = !props.isFormValid;
  }
  return (
    <AppBar
      position='sticky'
      className='panel-footer'>
      <Toolbar>
        <div
          className='contact-form-button-container padding-sm'>
          {getSecondaryButton(form, props)}
          {getPrimaryButton(form, buttonDisabled, props)}
        </div>
      </Toolbar>
    </AppBar>
  );
};

function mapStateToProps(state) {
  return {
    searchValue: state.contactsData.searchValue
  };
}

export default connect(mapStateToProps, {
  closeModal,
  getContactsData: contactActions.fetchContactsList,
  getMyUserAccountData,
  openModal,
  submitContactForm,
  verifyAndSubmitContactForm,
  uploadContactAvatar: contactActions.uploadContactAvatar
})(PanelFooter);

PanelFooter.propTypes = {
  form: PropTypes.string.isRequired,
  isFormValid: PropTypes.bool,
  isFormDirty: PropTypes.bool
};

function getSecondaryButton(form, props) {

  let buttonLabel = '';
  let buttonClasses = [];
  let labelClasses = [];
  switch (form) {
    case FORMS.CONTACT_INFO:
    case FORMS.USER_PROFILE_FORM:
      buttonLabel = 'Cancel';
      buttonClasses.push('cancel-button');
      break;
    default:
      throw new Error(`${form} is not a valid option`); /* ` */
  }
  return (
    <Button
      className={['button-primary', ...buttonClasses].join(' ')}
      onClick={() => {
        switch (form) {
          case FORMS.CONTACT_INFO:
          case FORMS.USER_PROFILE_FORM:
            props.closeModal();
            break;
          default:
            throw new Error(`${form} is not a valid option`); /* ` */
        }
      }}
      disableRipple
      color='primary'>
      <span className={['button-primary-label', ...labelClasses].join(' ')}>{buttonLabel}</span>
    </Button>
  );
}

function getPrimaryButton(form, buttonDisabled, props) {
  let buttonLabel = '';
  let classes = [];
  switch (form) {
    case FORMS.CONTACT_INFO:
      buttonLabel = props.isUnverified ? 'Verify & Save' : 'Save';
      classes.push('button-orange');
      break;
    case FORMS.USER_PROFILE_FORM:
      buttonLabel = 'Save Profile';
      classes.push('button-orange');
      break;
    default:
      throw new Error(`${form} is not a valid option`); /* ` */
  }
  if (buttonDisabled) {
    classes.push('button-primary-disabled');
  }
  const submitContactForm = props.isUnverified ? props.verifyAndSubmitContactForm : props.submitContactForm;
  return (
    <Button
      disableRipple
      onClick={async () => {
        switch (form) {
          case FORMS.CONTACT_INFO:
            const submitContactFormResults = await submitContactForm(FORMS.CONTACT_INFO, props.uploadingImage);
            if (!submitContactFormResults.error) {
              props.closeModal();
            }
            break;
          case FORMS.USER_PROFILE_FORM:
            const submitUserProfileFormResults = await submitContactForm(FORMS.USER_PROFILE_FORM);
            if (!submitUserProfileFormResults.error) {
              if (props.uploadingImage) {
                if (submitUserProfileFormResults && submitUserProfileFormResults.id) {
                  await props.uploadContactAvatar(FORMS.USER_PROFILE_FORM, submitUserProfileFormResults.id);
                } else {
                  await props.uploadContactAvatar(FORMS.USER_PROFILE_FORM);
                }
                location.reload();
              }
              await props.getMyUserAccountData();
            }
            break;
          default:
            console.log('submitted');
        }
      }}
      disabled={buttonDisabled}
      className={['button-primary', ...classes].join(' ')}
      color='primary'>
      <span className='button-primary-label label-white'>{buttonLabel}</span>
    </Button>
  );
}

getPrimaryButton.propTypes = {
  closeModal: PropTypes.func.isRequired,
  getContactsData: PropTypes.func.isRequired,
  getMyUserAccountData: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  submitContactForm: PropTypes.func.isRequired,
  verifyAndSubmitContactForm: PropTypes.func.isRequired,
  uploadContactAvatar: PropTypes.func.isRequired,
  uploadingImage: PropTypes.bool.isRequired,
  isUnverified: PropTypes.bool
};

getSecondaryButton.propTypes = {
  closeModal: PropTypes.func.isRequired
};
