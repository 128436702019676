import * as environments from '../constants/environments';

const host: string = window.location.host;

let api_domain = 'https://sampark-api-dev.yds.org';
let images_domain = 'https://images-qa.haridham.app';
let environment = environments.DEV;
let google_address_api_key = null;
let growthbook_api_key = '';

switch (host) {
  case 'red-sampark-dev.web.app':
    environment = environments.DEV;
    api_domain = 'https://sampark-api-dev.yds.org';
    images_domain = 'https://images-qa.haridham.app';
    google_address_api_key = 'AIzaSyBcvUDquqWZ4Pesq6NnZlIJ785B8ON4mo0';
    growthbook_api_key = 'dev_IQvZnN25fUl0TmIMfnfBwwI7MxXBpkFGEwZ01Y4e0';
    break;
  case 'sampark-dev.yds.org':
    environment = environments.DEV;
    api_domain = 'https://sampark-api-dev.yds.org';
    images_domain = 'https://images-qa.haridham.app';
    google_address_api_key = 'AIzaSyBcvUDquqWZ4Pesq6NnZlIJ785B8ON4mo0';
    growthbook_api_key = 'dev_IQvZnN25fUl0TmIMfnfBwwI7MxXBpkFGEwZ01Y4e0';
    break;
  case 'sampark.yds.org':
    environment = environments.PROD;
    api_domain = 'https://sampark-api.yds.org';
    images_domain = 'https://images.haridham.app';
    google_address_api_key = 'AIzaSyDL0wGeDKwlOOp7rNk3MbWdtGDWwwHpdTU';
    growthbook_api_key = 'prod_7kFcu2qraEotjCiv8dtJjKUQRavhrf0Lv2Hv9g4';
    break;
  case 'sampark-qa.yds.org':
    environment = environments.QA;
    api_domain = 'https://sampark-api-qa.yds.org';
    images_domain = 'https://images-qa.haridham.app';
    google_address_api_key = 'AIzaSyBcvUDquqWZ4Pesq6NnZlIJ785B8ON4mo0';
    growthbook_api_key = 'qa_IJViMY76G5vtlQJ2W5ZEjsCNrvZtYTWA67NURidSSE';
    break;
  case 'localhost:8080':
  case 'localhost:3000':
    environment = environments.LOCAL;
    // api_domain = 'http://localhost:3090';
    // api_domain = "http://10.60.60.82:3090";
    api_domain = 'https://sampark-api-dev.yds.org';
    images_domain = 'https://images-qa.haridham.app';
    google_address_api_key = 'AIzaSyBcvUDquqWZ4Pesq6NnZlIJ785B8ON4mo0';
    growthbook_api_key = 'dev_IQvZnN25fUl0TmIMfnfBwwI7MxXBpkFGEwZ01Y4e0';
    break;
}

export const ROOT_URL = api_domain;
export const ENV = environment;
export const IMAGES_URL = images_domain;

export const GOOGLE_ADDRESS_API_KEY = google_address_api_key;
export const GOOGLE_ADDRESS_VALIDATION_API = `https://addressvalidation.googleapis.com/v1:validateAddress?key=${google_address_api_key}`;

export const GROWTHBOOK_API = `https://gb-api.harisumiran.app/api/features/${growthbook_api_key}`;

export const API = {
  ADMIN_VIEW: {
    GET_USERS_BY_STATUS: ROOT_URL + '/api/user/listUsersByStatus/',
    GET_SAMPARK_USERS_BY_STATUS: (status: string) =>
      ROOT_URL + '/api/user/listUsersByStatus/' + status,
    SAMBANDH_PENDING_USERS: ROOT_URL + '/api/members/sambandh/pendingUsers',
    SAMBANDH_UPDATE_USER_STATUS: ROOT_URL + '/api/members/sambandh/userStatus/'
  },
  AUTHENTICATION: {
    SIGN_IN: ROOT_URL + '/api/auth/signin',
    MY_USER_ACCOUNT: ROOT_URL + '/api/members/sampark/me'
  },
  DASHBOARD_VIEW: {
    GET_DASHBOARD_DATA: ROOT_URL + '/api/dashboard'
  },
  NOTIFICATIONS_VIEW: {},
  CONTACTS_VIEW: {
    MEMBERS_API: ROOT_URL + '/api/members',
    GET_CONTACT_BY_ID: (id: string, followUpByDetails?: string) => {
      let base = ROOT_URL + `/api/members/${id}`;
      if (followUpByDetails) {
        base = `${base}?followUpByDetails=true`;
      }
      return base;
    },
    UPLOAD_AVATAR: (id: string) => ROOT_URL + `/api/members/${id}/photo`,
    DELETE_MEMBERS_API: (id: string) => ROOT_URL + `/api/members/${id}`,
    GET_CONTACT_OPTIONS: ROOT_URL + '/api/members/contactOptions',
    GET_EXPORT_DATA: ROOT_URL + '/api/members/export',
    INVITE_MEMBER: ROOT_URL + '/api/signup/inviteMember',
    GET_ACTIVE_KARYAKARTAS: ROOT_URL + '/api/applications/karyakarta-users',
    SET_FOLLOW_UP_KARYAKARTAS: ROOT_URL + '/api/members/assign-followup',
    VERIFY_MEMBERS_API: (id: string) => ROOT_URL + `/api/members/verify/${id}`
  },
  CONTACT_US_FORM: {
    SUBMIT_CONTACT_US_EMAIL: ROOT_URL + '/api/support/emailServiceDesk'
  },
  EVENTS_VIEW: {
    EVENTS_API: ROOT_URL + '/api/events',
    EVENT_GALLERY_API: ROOT_URL + '/api/content-images/'
  },
  REGISTRATION: {
    SIGNUP: ROOT_URL + '/api/verify/updateVerifiedMemberData'
  },
  CONTACT_PANEL: {
    GET_FILTER_CITY_SUGGESTIONS_URL: ROOT_URL + '/api/cities?q='
  },
  APPROVAL_PANEL: {
    UPDATE_USER_ACCESS: ROOT_URL + '/api/user/assignUserAccess',
    SET_USER_STATUS: ROOT_URL + '/api/user/updateStatus'
  },
  BROADCAST_VIEW: {
    BROADCAST_MESSAGES_API: ROOT_URL + '/api/notifications'
  },
  IMAGE_UPLOAD: ROOT_URL + '/api/imgs/upload',
  READING_VIEW: {
    READING_CONTENT: ROOT_URL + '/api/readingPublication'
  },
  ALBUMS: {
    BASE_ALBUM: ROOT_URL + '/api/album/',
    COVER_IMAGE_SUFFIX: '/coverImage'
  },
  YOG_REPORT: {
    YOG_REPORT_API: ROOT_URL + '/api/yog-report/report'
  },
  FOLLOW_UP: {
    GET_FOLLOW_UP_CONTACTS: ROOT_URL + '/api/followup/contacts',
    TAGS: ROOT_URL + '/api/followup/tags'
  },
  FOLLOW_UP_ADMIN: {
    GET_FOLLOW_UP_KKS: ROOT_URL + '/api/user/follow-up',
    GET_FOLLOW_UP_CONTACTS_FOR_KK: (id: string) => ROOT_URL + '/api/followup?memberID=' + id
  },
  ACCESS: {
    AUDIENCES: ROOT_URL + '/api/access/audiences'
  },
  INVITATION: {
    GENERATE_INVITATION: (memberId: string) =>
      ROOT_URL + `/api/invitation/generateInvitation/${memberId}`,
    GET_INVITATION_STATUS_FOR_MEMBER_IDS: ROOT_URL + '/api/invitation/workshopInvitationStatus'
  },
  FAMILY_TREE: {
    FAMILIES: ROOT_URL + '/api/families',
    FAMILY_BY_ID: (id: string) => `${ROOT_URL}/api/families/${id}`,
    CREATE_FAMILY: `${ROOT_URL}/api/families/`,
    UPDATE_FAMILY: (id: string) => `${ROOT_URL}/api/families/${id}`
  },
  YOG_ADMIN: {
    // Default value for `eventId` is for SPS 2023.
    // This should probably come from .env file.
    EVENT_REGISTRATIONS: (eventId: string) => ROOT_URL + `/api/yog/event/${eventId}/registrations`,
    GET_REGISTERABLE_EVENTS: ROOT_URL + `/api/yog/events`,
    UPDATE_REGISTRATION: (registrationId: string) =>
      ROOT_URL + `/api/yog/registration/${registrationId}`
  },
  ATTRIBUTE: {
    GET_ALL_ATTRIBUTES: `${ROOT_URL}/api/rule-engine/attributes`,
    GET_ALL_FIELDS: `${ROOT_URL}/api/fields`,
    UPDATE_ATTRIBUTE: (key: string) => `${ROOT_URL}/api/rule-engine/attributes/${key}`,
    CREATE_ATTRIBUTE: `${ROOT_URL}/api/rule-engine/attributes/`,
    REMOVE_ATTRIBUTE: (id: string) => `${ROOT_URL}/api/rule-engine/attributes/${id}`
  },
  RULE: {
    CREATE_RULE: `${ROOT_URL}/api/rule-engine/rules`,
    UPDATE_RULE: `${ROOT_URL}/api/rule-engine/rules`,
    GET_ALL_RULES: `${ROOT_URL}/api/rule-engine/rules`,
    REMOVE_RULE: (id: string) => `${ROOT_URL}/api/rule-engine/rules/${id}`
  }
};

export const DRAWER_COMPONENTS = {
  APPROVAL_FORM: 'approval_form',
  EVENT_FORM: 'event_form',
  NAV_PANEL: 'nav_panel',
  SAMBANDH_APPROVAL: 'sambandh_approval',
  FILTER_FORM: 'filter_form',
  READING_PANEL: 'reading_panel'
};

export const NOTIFICATION_TYPES = {
  APPROVAL: 'Approval',
  MESSAGE: 'Message'
};
