import React, { useCallback, useEffect, useRef } from 'react';
import { getFormValues } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { closeModal } from '../../actions/common.actions';
import FilterForm from './FilterForm';
import { FILTER_FORM } from '../../constants/forms';
import { contactActions } from '../../views/Contacts/contacts.ducks';
import { RootState } from '../../reducers/index.types';

import icon_filter from '../../assets/icons/icon_filter.svg';
import icon_close from '../../assets/icons/icon_cross.svg';
import { useFeature } from '@growthbook/growthbook-react';
import { selectIsReadOnlyAccess } from '../../reducers/authentication.reducer';

const FilterPanel = () => {
  const { searchValue } = useSelector((state: RootState) => state.contactsData);

  const state = useSelector((state: RootState) => state);
  const isReadOnly = useSelector(selectIsReadOnlyAccess);

  const updatedValues = getFormValues(FILTER_FORM)(state) || null;
  const filterValues = useRef<string>('');

  const dispatch = useDispatch();
  const actions = bindActionCreators(
    { closeModal, getContactsData: contactActions.fetchContactsList },
    dispatch
  );

  const inviteToWorkshopFlag = useFeature('sampark.invite-to-workshop').on;

  const updateContactsData = useCallback(() => {
    actions.getContactsData({ inviteToWorkshopFlag, isReadOnly, searchValue });
  }, [updatedValues]);

  useEffect(() => {
    const id = setTimeout(() => {
      if (updatedValues || updatedValues === undefined) {
        const newFilterValues = JSON.stringify(updatedValues);
        if (filterValues.current !== newFilterValues) {
          filterValues.current = newFilterValues;
          updateContactsData();
        }
      }
    }, 0);
    return () => clearTimeout(id);
  }, [updatedValues]);

  return (
    <div className="filter-panel">
      <div className="padding-left padding-right filter-panel-header">
        <div className="filter-panel-top-row">
          <img src={icon_filter} />
          <span className="title-text padding-xsm-right">All filters</span>
          {/*TODO change this to button image */}
          <img className="filter-form-dismiss" onClick={actions.closeModal} src={icon_close} />
        </div>
      </div>
      <FilterForm />
    </div>
  );
};

export default FilterPanel;
