import { FC, Fragment, useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { JssProvider } from 'react-jss';
import { create } from 'jss';
import { createGenerateClassName, jssPreset, StylesProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Provider } from 'react-redux';
import store from './helpers/store';
import { PrivateRoute } from './components/route-components/PrivateRoute';
import SignInLayout from './layouts/SignInLayout';
import { SamparkLayout } from './layouts/SamparkLayout/SamparkLayout';
import './assets/favicon';
import { ErrorView } from './views/ErrorView';

import './styles/index.scss';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme/customTheme';
import { LOGGEDINUSERINFOTOKEN, SAMPARKTOKEN } from './config/config.tokens';
import { getMyUserAccountDataAPI } from './apis/commonAPIs';
import { LoadingSpinner } from './components/LoadingSpinner/LoadingSpinner';
import { authActions, userAccessActions } from './constants/actions';
import { UNAUTHORIZED, ERROR } from './constants/status';

const generateClassName = createGenerateClassName({
  // dangerouslyUseGlobalCSS: true,
  seed: 'Sampark'
});
const jss = create({
  ...jssPreset(),
  insertionPoint: 'jss-insertion-point'
});

export const App: FC = () => {
  const [isInitialized, setIsInitialized] = useState(false);

  /**
   * When app is loaded, and a token is in local storage,
   * we validate it by sending an API call to /me.
   * - If it succeeds, then we set the user as authenticated
   * - If it fails, we delete the token from local storage
   */
  useEffect(() => {
    const validateStoredSamparkToken = async () => {
      try {
        const samparkMemberInfo = await getMyUserAccountDataAPI();

        if (samparkMemberInfo.error) {
          // Delete token and log user out
          localStorage.removeItem(SAMPARKTOKEN);
          localStorage.removeItem(LOGGEDINUSERINFOTOKEN);
          store.dispatch({
            type: authActions.AUTH_ERROR,
            payload: ERROR.status
          });
        } else {
          store.dispatch({
            type: userAccessActions.GET_MY_USER_ACCOUNT,
            value: samparkMemberInfo
          });
          // TODO: Remove this user info from local storage
          localStorage.setItem(
            LOGGEDINUSERINFOTOKEN,
            JSON.stringify({
              firstName: samparkMemberInfo.firstName,
              lastName: samparkMemberInfo.lastName,
              profileImageUrl: samparkMemberInfo.profileImageUrl,
              status: samparkMemberInfo.status,
              _id: samparkMemberInfo.id
            })
          );
          store.dispatch({ type: authActions.AUTH_USER_SAMPARK });
        }
      } catch (e: any) {
        console.error(e);
        // Delete token and log user out
        localStorage.removeItem(SAMPARKTOKEN);
        localStorage.removeItem(LOGGEDINUSERINFOTOKEN);
        store.dispatch({
          type: authActions.AUTH_ERROR,
          payload: UNAUTHORIZED.status
        });
      } finally {
        setIsInitialized(true);
      }
    };

    if (!isInitialized) {
      // Perform check if user is authenticated here.
      if (localStorage.getItem(SAMPARKTOKEN)) {
        validateStoredSamparkToken();
      } else {
        setIsInitialized(true);
      }
      return () => console.log('App initialized');
    }
  }, [isInitialized]);

  return (
    <JssProvider jss={jss}>
      <Fragment>
        <CssBaseline />
        <Provider store={store}>
          <StylesProvider injectFirst generateClassName={generateClassName}>
            <ThemeProvider theme={theme}>
              {isInitialized ? (
                <BrowserRouter>
                  <Switch>
                    <Route path="/" exact component={SignInLayout} />
                    <Route path="/auth" component={SignInLayout} />
                    <Route path="/sampark" component={PrivateRoute(SamparkLayout)} />
                    <Route path="/error" component={ErrorView} />
                    <Redirect to="/error" />
                  </Switch>
                </BrowserRouter>
              ) : (
                // Replace with some splash screen
                <LoadingSpinner />
              )}
            </ThemeProvider>
          </StylesProvider>
        </Provider>
      </Fragment>
    </JssProvider>
  );
};
