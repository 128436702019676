import { FC, useCallback } from 'react';
import { makeStyles } from 'tss-react/mui';

import { AddressDetailsType, MembersType } from '../../../../../types';
import { MembersListItem } from './MembersListItem';
import { useIntersectionObserver } from '../../../../../v2/hooks/useIntersectionObserver';
import { IRegistration } from '../../yogAdmin.duck';

export type OptionType = {
  value: string | AddressDetailsType;
  name: string;
};

type MembersListProps = {
  className?: string;
  contentClassName?: string;
  registrations: IRegistration[];
  familyMembers?: MembersType[];
  addressOptions?: OptionType[];
  showAddButton?: boolean;
  showFamilyAssigned?: boolean;
  showRemoveButton?: boolean;
  showAssignButton?: boolean;
  showAddDisabled?: boolean;
  selectedFamilyId?: string;
  total?: number;
  isMobile?: boolean;
  onClick?: (registration: IRegistration) => void;
  onAdd?: (member: MembersType) => void;
  onRemove?: (memberId: string) => void;
  fetchMore?: () => void;
  onAssign?: (memberId: string) => void;
  approveRegistration: (registration: IRegistration) => void;
  rejectRegistration: (registration: IRegistration) => void;
  onClickFamilyId: (familyId: string) => void;
  hideQuickActions?: boolean;
  openConfirmRemoveHoldModal: (registration: IRegistration) => void;
};

export const MembersList: FC<MembersListProps> = ({
  approveRegistration,
  rejectRegistration,
  className,
  contentClassName = '',
  registrations,
  familyMembers = [],
  onClick,
  onClickFamilyId,
  addressOptions,
  selectedFamilyId = '',
  fetchMore,
  isMobile,
  total = 0,
  hideQuickActions,
  openConfirmRemoveHoldModal
}) => {
  const { classes, cx } = useStyles();
  const callBack = useCallback(
    (entries) => {
      // only if intersecting meaning, its coming into view, we fetch more
      if (entries && entries[0].isIntersecting) {
        if (fetchMore) {
          fetchMore();
        }
      }
    },
    [fetchMore]
  );

  useIntersectionObserver({
    root: document.getElementById('rootMemberListId'),
    target: document.getElementById('loadingMoreId'),
    intersectedCallBack: callBack,
    rootMargin: '0px 0px 0px 0px',
    threshold: 0,
    subscribe: true
  });

  const checkIsSelected = useCallback(
    (member) => {
      const findIndex = familyMembers.findIndex((m) => m._id === member._id);
      return findIndex > -1;
    },
    [selectedFamilyId, familyMembers]
  );
  return (
    <div id={'rootMemberListId'} className={cx(classes.listContainer, className)}>
      {registrations.length > 0
        ? registrations.map((registration) => {
            return (
              <MembersListItem
                className={contentClassName}
                key={registration.member.memberId || registration.registrationId}
                id={registration.registrationId}
                registration={registration}
                addressOptions={addressOptions}
                onClick={onClick}
                isSelected={checkIsSelected(registration)}
                isMobile={isMobile}
                approveRegistration={approveRegistration}
                rejectRegistration={rejectRegistration}
                onClickFamilyId={onClickFamilyId}
                hideQuickActions={hideQuickActions}
                openConfirmRemoveHoldModal={openConfirmRemoveHoldModal}
              />
            );
          })
        : null}
      {total > registrations.length ? <div id={'loadingMoreId'}>Loading ....</div> : null}
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  listContainer: {
    overflowY: 'auto',
    [theme.breakpoints.up('sm')]: {
      maxHeight: 'calc(100vh - 85px)'
    }
  }
}));
