import { initializeApp } from 'firebase/app';
// Add the Firebase services that you want to use
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { ENV } from './config.constants';
import * as environments from '../constants/environments';

const prodConfig = {
  apiKey: 'AIzaSyCzqgm4ezGR3IDnjgcRsNA5jVVbOuoMkdE',
  authDomain: 'sampark.yds.org',
  databaseURL: 'https://sampark-prod.firebaseio.com',
  projectId: 'sampark-prod',
  storageBucket: 'sampark-prod.appspot.com',
  messagingSenderId: '714426656660'
};

const devConfig = {
  apiKey: 'AIzaSyBmja13i4uKodrwWNySH4EKPl_Vskf0YDo',
  authDomain: 'sampark-dev.firebaseapp.com',
  databaseURL: 'https://sampark-dev.firebaseio.com',
  projectId: 'sampark-dev',
  storageBucket: 'sampark-dev.appspot.com',
  messagingSenderId: '873229530077'
};

const qaConfig = {
  ...devConfig,
  authDomain: 'sampark-qa.yds.org'
};

function getFirebaseConfigFromEnv(env: string) {
  switch (env) {
    case environments.PROD:
      return prodConfig;
    case environments.QA:
      return qaConfig;
    case environments.DEV:
    default:
      return devConfig;
  }
}

const app = initializeApp(getFirebaseConfigFromEnv(ENV));

export const firebaseAuth = getAuth(app);
export const googleProvider = new GoogleAuthProvider().setCustomParameters({
  prompt: 'select_account'
});
