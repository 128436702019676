import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// material
import Dialog from '@material-ui/core/Dialog';
// Project Files
import * as MODAL_COMPONENTS from '../../constants/modals';
import {
  ErrorModal
} from './index';
import { closeModal } from '../../actions/common.actions';
import DeleteContactsModal from './DeleteContactsModal';
import ContactUsModal from './ContactUsModal';
import InviteMemberModal from './InviteMemberModal';
import RejectBroadcastMessageModal from './RejectBroadcastMessageModal';
import CancelBroadcastModal from './CancelBroadcastModal';
import RejectSambandhModal from './RejectSambandhModal';
import EventGalleryModal from './EventGalleryModal';
import RejectReadingModal from './RejectReadingModal';
import ContactPanel from '../contact-panel/ContactPanel';
import { StyledContactInfo } from '../../views/Contacts/ContactInfo';
import FilterPanel from '../filter-panel/FilterPanel';
import ReadingPanel from '../reading-panel/ReadingPanel';


class ModalController extends React.Component {
  constructor(props) {
    super(props);
  }

  renderModalContent() {
    const { modalComponent, modalProps } = this.props;

    switch (modalComponent) {
      case MODAL_COMPONENTS.CONTACT_INFO_READ_ONLY:
        return <StyledContactInfo />
      case MODAL_COMPONENTS.CONTACT_FORM:
        return <ContactPanel />;
      case MODAL_COMPONENTS.USER_ACCOUNT_PANEL:
        return <ContactPanel myUserAccount />;
      case MODAL_COMPONENTS.FILTER_FORM:
        return <FilterPanel />;
      case MODAL_COMPONENTS.VIEW_READING_MODAL:
        return <ReadingPanel />;
      case MODAL_COMPONENTS.ERROR_MODAL:
        return <ErrorModal errorMessage={modalProps.errorMessage} />;
      case MODAL_COMPONENTS.DELETE_CONTACTS_MODAL:
        return <DeleteContactsModal contactsToDelete={modalProps.selectedContacts} />;
      case MODAL_COMPONENTS.CONTACT_US_MODAL:
        return <ContactUsModal loggedInUser={modalProps.loggedInUser} />;
      case MODAL_COMPONENTS.INVITE_MEMBER_MODAL:
        return <InviteMemberModal contact={modalProps.contact} />;
      case MODAL_COMPONENTS.REJECT_BROADCAST_MODAL:
        return <RejectBroadcastMessageModal />;
      case MODAL_COMPONENTS.CANCEL_BROADCAST_MODAL:
        return <CancelBroadcastModal message={modalProps.message} />;
      case MODAL_COMPONENTS.REJECT_SAMBANDH_MODAL:
        return <RejectSambandhModal contact={modalProps.contact} />;
      case MODAL_COMPONENTS.EVENT_GALLERY_MODAL:
        return <EventGalleryModal event={modalProps.event} />;
      case MODAL_COMPONENTS.REJECT_READING_MODAL:
        return <RejectReadingModal />;
      default:
        return <div></div>;
      // throw new Error(`${modalComponent} is not a valid modal component`); // `
    }
  }

  render() {
    const { showModal, modalProps } = this.props;
    const className = modalProps.className ?? '';
    const fullScreen = window.innerWidth < 768;
    const maxWidth = modalProps.maxWidth == 'md' ? 'md' : 'sm';

    return (
      <Dialog
        className={`contacts-selected-dialog ${className}`}
        fullScreen={fullScreen}
        open={showModal}
        onClose={this.props.closeModal}
        aria-labelledby='responsive-dialog-title'
        fullWidth
        maxWidth={maxWidth}
        disableBackdropClick
        disableEscapeKeyDown
      >
        {this.renderModalContent()}
      </Dialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    modalComponent: state.commonData.modalComponent,
    modalProps: state.commonData.modalProps,
    showModal: state.commonData.showModal
  };
}

export default connect(mapStateToProps, { closeModal })(ModalController);

ModalController.propTypes = {
  modalComponent: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  modalProps: PropTypes.object.isRequired,
  showModal: PropTypes.bool.isRequired
};
