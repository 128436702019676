import { signInWithRedirect, signOut } from 'firebase/auth';
import { firebaseAuth, googleProvider } from '../config/config.firebase';
/*
 * login user with google (firebase provides this method)
 * redirects to google sign in
 */
export function loginWithGoogle() {
  return signInWithRedirect(firebaseAuth, googleProvider);
}
/*
 * sign out of google (firebase provides this method)
 */
export function logoutGoogle() {
  return signOut(firebaseAuth);
}
