import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid
} from '@material-ui/core';
import { AvatarWrapper } from '../../../components/Avatar/AvatarWrapper';
import { HoverImage } from '../../../components/hover-image/HoverImage';
import icon_close_small_grey from '../../../assets/icons/icon_close_small_grey.svg';
import icon_close_small_black from '../../../assets/icons/icon_close_small_black.svg';

const useStyles = withStyles({
  listName: {
    textTransform: 'capitalize',
    margin: 'auto',
    paddingLeft: '1rem'
  },
  location: {
    color: '#899EB0',
    fontSize: '1rem'
  },
  name: {
    fontSize: '1rem'
  },
  state: {
    textTransform: 'uppercase'
  },
  selectedKK: {
    border: '1px solid #C3D2DF',
    padding: '0.5rem 1rem',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '1rem',
    height: '4rem',
    marginBottom: '1rem'
  },
  selectedKKHover: {
    border: '1px solid #899EB0',
    padding: '0.5rem 1rem',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    fontSize: '1rem',
    height: '4rem',
    marginBottom: '1rem'
  }
});

const SelectedKK = (props) => {
  const {
    selectedKK,
    onClear,
    classes
  } = props;

  const [ hoverCross, setHoverCross ] = useState(false);

  return (<Grid container className={hoverCross ? classes.selectedKKHover : classes.selectedKK}>
    <Grid item xs={1}>
      <AvatarWrapper size='40' contact={selectedKK} />
    </Grid>
    <Grid item xs={10} className={classes.listName}>
      <div className={classes.name}>
        {selectedKK.firstName} {selectedKK.lastName}
      </div>
      <div className={classes.location}>
        {selectedKK.addressDetails.city}, <span className={classes.state}>{selectedKK.addressDetails.state}</span>
      </div>
    </Grid>
    <Grid item xs={1} style={{ margin: 'auto' }}>
      <HoverImage
        src={icon_close_small_grey}
        onMouseEnter={() => setHoverCross(true)}
        onMouseExit={() => setHoverCross(false)}
        hoverSrc={icon_close_small_black}
        onClick={onClear} />
    </Grid>
  </Grid>);
};

SelectedKK.propTypes = {
  selectedKK: PropTypes.object.isRequired,
  onClear: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};
export default useStyles(SelectedKK);
