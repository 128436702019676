/* eslint-disable */
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import Button from '@material-ui/core/Button'; //Todo: Use @mui/material/Button. Currently its style overrides className styles
import { Dialog } from '@mui/material';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import { MembersList } from '../components/MembersList/MembersList';
import { useAppDispatch, useAppSelector } from '../../../../helpers/store';
import {
  createOrUpdateFamily,
  resetFamilyTreeContent,
  removeFromFamily,
  assignToExistingFamily,
  getFamilyRecommendations,
  searchMembers,
  ISearchMemberQuery,
  FamilyMemberType,
  getFamilyById
} from '../familyTree.duck';

import { SaveModal } from '../components/Modal/SaveModal';
import { getMemberIdsFromMembers } from '../util/familyAdapter';
import { formatAddress } from '../util/utils';
import { AddressDetailsType, MembersType } from '../../../../types';
import { updateContactAPI } from '../../../../apis/contactAPIs';
import { useMemberDetailsModal } from '../hooks/useMemberDetailsModal';
import { LoadingSpinner } from '../../../../components/LoadingSpinner/LoadingSpinner';

const FamilyTreeContent = () => {
  const { classes, cx } = useStyles();
  const { loading, fetchAndOpenMemberDetails } = useMemberDetailsModal();
  const dispatch = useAppDispatch();
  const familyRelation = useAppSelector((state) => state.admin.familyTree?.familyRelation);
  const familyRelationMembers = useAppSelector(
    (state) => state.admin.familyTree?.familyRelation?.members
  );
  const recommendations = useAppSelector((state) => state.admin.familyTree?.recommendations);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [updateMember, setUpdateMember] = useState<Record<string, FamilyMemberType>>({});

  useEffect(() => {
    return () => {
      dispatch(resetFamilyTreeContent());
    };
  }, []);

  useEffect(() => {
    if (familyRelation.id) {
      dispatch(getFamilyById(familyRelation.id));
    }
  }, [familyRelation.id]);

  const hasMultipleAddresses = useMemo(() => {
    const addresses = familyRelationMembers.map((m: FamilyMemberType) => {
      if (!m.addressDetails) return '';
      const { address1, city, state, country } = m.addressDetails;
      return formatAddress(address1, city, state, country);
    });
    const uniqueAddresses = addresses
      .filter((i) => i)
      .filter((item: string, i: number, ar: string[]) => ar.indexOf(item) === i);
    return uniqueAddresses.length > 1;
  }, [familyRelationMembers]);

  const onUpdateContacts = useCallback(async () => {
    const members = Object.keys(updateMember).map((m) => ({
      ...updateMember[m],
      _id: m,
      id: m
    }));
    members.map(async (member) => {
      const updateContactResponse = await updateContactAPI(member);
      if (!updateContactResponse.error) {
        console.log('Success', updateContactResponse);
      } else {
        console.log('Error', updateContactResponse);
      }
      setIsEditMode(false);
      //refetch updated data
      if (member.familyId) {
        dispatch(getFamilyById(member.familyId));
      }
    });
  }, [updateMember]);

  const NoContentContainer = () => {
    const { classes } = useStyles();
    return (
      <div className={classes.noContentContainer}>
        <p>Add a contact to tag a family</p>
      </div>
    );
  };
  useEffect(() => {
    if (familyRelationMembers?.length > 0) {
      dispatch(getFamilyRecommendations(getMemberIdsFromMembers(familyRelationMembers)));
    }
  }, [familyRelationMembers]);

  const handleSubmitClick = useCallback(() => {
    setOpenConfirmModal(false);
    dispatch(createOrUpdateFamily(familyRelation));
    const timer = setTimeout(() => {
      dispatch(searchMembers({ name: '', pageNumber: 1 } as ISearchMemberQuery));
    }, 500);
    return () => clearTimeout(timer);
  }, [familyRelation]);

  const handleCancelClick = useCallback(() => {
    dispatch(resetFamilyTreeContent());
  }, []);

  const handleRemove = (memberId: string) => {
    dispatch(removeFromFamily(memberId));
  };

  const handleAssign = (memberId: string) => {
    dispatch(assignToExistingFamily(memberId));
    //To do: remove member from recommended members array
  };

  const addressOptions = useMemo(() => {
    const addresses =
      familyRelation?.members?.length > 0
        ? familyRelation?.members?.map((m) => m.addressDetails)
        : [];
    const addressOptions = addresses
      .filter((f) => f)
      .map((address: AddressDetailsType) => {
        const { address1, city, state, country } = address;
        const val = formatAddress(address1, city, state, country);
        return {
          name: val,
          value: address
        };
      });

    return [...(new Map(addressOptions.map((addr) => [addr['name'], addr])).values() as any)];
  }, [familyRelation?.members]);

  const onAddressChange = (member: FamilyMemberType) => {
    const updateMemberData = {
      ...updateMember,
      [member._id]: member
    };
    setUpdateMember(updateMemberData);
  };

  const onMemberClick = (memberId: string) => {
    fetchAndOpenMemberDetails(memberId);
  };

  return (
    <div className={classes.container}>
      {loading ? <LoadingSpinner className={classes.loading} /> : null}
      <header className={classes.header}>
        <h4 className={classes.title}>Family Tree</h4>
        {familyRelation?.members?.length > 0 ? (
          <p className={classes.subTitle}>
            Shared with <span>{familyRelation?.members?.length} Contacts</span>
          </p>
        ) : null}
      </header>
      {hasMultipleAddresses && familyRelation.id && !isEditMode ? (
        <div className={classes.addressUpdateBanner}>
          Multiple mailing addresses are detected. Would you like to update them to the same
          address?
          <button
            className={classes.addressUpdateBtn}
            onClick={() => {
              setIsEditMode(true);
            }}
          >
            Tap to update
          </button>
          .
        </div>
      ) : null}
      <div className={classes.contentContainer}>
        {familyRelation && familyRelation.members?.length > 0 ? (
          <>
            <MembersList
              className={classes.contentListContainer}
              contentClassName={classes.contentListItem}
              members={Object.values(familyRelation.members)}
              addressOptions={addressOptions}
              isEditMode={isEditMode}
              onClick={onMemberClick}
              onAddressChange={onAddressChange}
              onRemove={handleRemove}
              isFamilyTreeContent
              showRemoveButton
            />

            {isEditMode ? (
              <div className={classes.addressUpdateButtons}>
                <button
                  className={classes.cancelButton}
                  onClick={() => {
                    setIsEditMode(false);
                  }}
                >
                  <CloseRoundedIcon />
                  Cancel
                </button>
                <button
                  className={cx(classes.cancelButton, classes.updateButton)}
                  disabled={Object.keys(updateMember).length === 0}
                  onClick={() => {
                    onUpdateContacts();
                  }}
                >
                  <CheckRoundedIcon />
                  Update
                </button>
              </div>
            ) : null}

            {!isEditMode && recommendations?.length > 0 ? (
              <div>
                <p className={classes.title}>Recommended Family Members</p>
                <p className={classes.subTitle}>Based on same mailing address</p>

                <MembersList
                  className={classes.contentListContainer}
                  contentClassName={cx(classes.contentListItem, classes.recommendedContact)}
                  members={Object.values(recommendations)}
                  onAssign={handleAssign}
                  showAssignButton
                  isFamilyTreeContent
                />
              </div>
            ) : null}
          </>
        ) : (
          <NoContentContainer />
        )}
      </div>
      {familyRelation.members.length > 0 && !isEditMode ? (
        <footer className={classes.footer}>
          <Button
            onClick={handleCancelClick}
            className="button-primary cancel-button"
            disableRipple
          >
            <span className="button-primary-label">Cancel</span>
          </Button>
          <Button
            onClick={() => setOpenConfirmModal(true)}
            disableRipple
            disabled={!familyRelation.isDirty}
            className={familyRelation.isDirty ? 'button-primary button-orange' : 'button-disabled'}
            color={'primary'}
          >
            <span className="button-primary-label">Confirm</span>
          </Button>
          <Dialog
            className={`contacts-selected-dialog`}
            fullScreen={false}
            open={openConfirmModal}
            onClose={() => setOpenConfirmModal(false)}
            aria-labelledby="responsive-dialog-title"
            fullWidth
            maxWidth={'sm'}
            disableEscapeKeyDown
          >
            <SaveModal
              title="Assign Family"
              detailMessage="Are sure you want to save the changes to the Family tree?"
              cancelCallBack={() => setOpenConfirmModal(false)}
              confirmCallBack={handleSubmitClick}
            />
          </Dialog>
        </footer>
      ) : null}
    </div>
  );
};

const useStyles = makeStyles()((theme) => ({
  container: {
    flex: '1 0 50%',
    background: '#fff',
    border: '1px solid #C3D2DF',
    borderRadius: '20px',
    padding: theme.spacing(2),
    maxHeight: '72vh',
    overflow: 'hidden',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      flex: '1 0 55%'
    },
    [theme.breakpoints.up('xl')]: {
      flex: '1 0 calc(100% - 440px)',
      maxHeight: '76vh'
    }
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 'calc(100% - 62px)',
    padding: theme.spacing(0, 0, 28, 0),
    overflowY: 'auto'
  },
  addressUpdateBanner: {
    background: '#DDE9FD',
    padding: theme.spacing(2),
    color: '#2256BF',
    fontFamily: 'Rubik',
    fontSize: '0.8rem',
    fontWeight: 400
  },
  addressUpdateBtn: {
    cursor: 'pointer',
    background: 'none',
    border: 'none',
    fontFamily: 'Rubik',
    fontSize: '0.8rem',
    fontWeight: 600,
    color: '#2256BF',
    textDecoration: 'underline',
    padding: theme.spacing(0, 0, 0, 0.5)
  },
  header: {
    borderBottom: '1px solid #E8EBF4'
  },
  footer: {
    borderTop: '1px solid #E8EBF4',
    width: '100%',
    position: 'absolute',
    bottom: '0px',
    left: '0px',
    padding: theme.spacing(4, 3),
    background: 'white',
    borderRadius: '0 0px 20px 20px',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  recommendedContact: {
    opacity: 0.5
  },
  filter: {},
  contentListContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(0, 1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '&::-webkit-scrollbar': {
      borderRadius: '9px',
      width: '4px'
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '9px'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '9px',
      backgroundColor: 'darkgrey'
    }
  },
  contentListItem: {
    border: '1px dashed #9CA1B1',
    borderRadius: '16px',
    padding: theme.spacing(2, 9, 2, 2),
    width: '25rem'
  },
  loading: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: 'rgba(0, 0, 0, 0.4)',
    zIndex: 2
  },
  noContentContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#5F698B',
    height: '100%',
    '& p': {
      opacity: 0.5
    }
  },
  subTitle: {
    color: '#A5ADC6',
    fontSize: '12px',
    marginTop: 0,
    marginBottom: theme.spacing(2),
    '& > span': {
      fontWeight: 'bold',
      color: '#848EAC'
    }
  },
  title: {
    fontSize: '1rem',
    fontFamily: 'Rubik',
    fontStyle: 'normal',
    fontWeight: '500',
    margin: theme.spacing(1, 0, 1)
  },
  addressUpdateButtons: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(12),
    display: 'flex'
  },
  cancelButton: {
    cursor: 'pointer',
    background: '#D63230',
    borderRadius: '9px',
    border: 'none',
    fontFamily: 'Rubik',
    fontSize: '1rem',
    fontWeight: 400,
    color: '#FFFFFF',
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginRight: theme.spacing(1)
    }
  },
  updateButton: {
    background: '#26C972',
    '&:disabled': {
      cursor: 'default',
      opacity: 0.5
    }
  }
}));

export default FamilyTreeContent;
