import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Switch, Redirect, Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
// material
import { Modal, Menu, MenuItem, Dialog } from '@material-ui/core';

import { isDone, oneOf, isError } from '../../../util/stage';
import { isMobileDevice } from '../../../util/windowUtils';
import { ActionCreators } from './followUpAdmin.duck';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { FollowUpKaryakartaTab } from '../../../v2/components/admin-tabs/followUpKaryakartaTab';
import FollowUpDetails from '../../FollowUpDetails';
import icon_close from '../../../assets/icons/icon_cross.svg';
import FollowUpModal from '../../Contacts/FollowUpModal';
import icon_arrow_left from '../../../assets/icons/icon_arrow_left.svg';

import './followUpAdmin.scss';

const FollowUpAdmin = (props) => {
  const {
    actions,
    followUpKKAdmins,
    stage,
    history,
    contactsStage,
    followUpContacts
  } = props;

  const isMobile = isMobileDevice();

  const [ selectedKK, setSelectedKK ] = useState(null);

  // set karyakarta modal for modal
  const [karyakartaForModal, setKaryakartaForModal] = useState(null);

  const openKaryakartaModal = karyakartaForModal ? true : false;
  // on mount, get kk list
  useEffect(() => {
    actions.fetchFollowUpKKList();

    // on unmount reset kk list
    return () => actions.resetFollowUpKKList();
  }, []);

  // when got kk list, set first as selected
  useEffect(() => {
    if (isDone(stage)) {
      // do not set default KK if mobile
      // or else user would directly be redicted to next view
      // and they would not see kk list
      if (followUpKKAdmins.length && !isMobile && !selectedKK) {
        setSelectedKK(followUpKKAdmins[0]);
      }
    }
  }, [stage]);

  // when kk is selected, route to that page
  useEffect(() => {
    if (selectedKK) {
      history.push('/sampark/admin/followupKK/' + selectedKK.memberID);
      actions.fetchFollowUpContactListForKK(selectedKK.memberID);
    }
  }, [selectedKK]);

  // set anchorEl for menu
  const [ anchorEl, setAnchorEl ] = useState(null);

  // set openFollowUpModal Dialog for change/assign followups
  const [ openFollowUpModal, setOpenFollowUpModal ] = useState(null);

  const renderMobileContainer = () => {
    return (
      <Switch>
        <Route
          exact
          path='/sampark/admin/followupKK'
          render={(routeProps) => {
            return (
              <FollowUpKaryakartaTab
                title='Sampark Sevako in My Area'
                type='follow-up-kk-admins' // need to use Constants
                items={followUpKKAdmins}
                onModalOpen={() => {}} // action to setSelectedKK
                onClick={setSelectedKK}
                showSearch
                emptyMessage='No Sampark Sevako in your area'
                stage={stage}
                selectedItem={selectedKK}
              />
            );
          }}
        />
        <Route
          path='/sampark/admin/followupKK/:kkid'
          render={(routeProps) => {
            const { match: { params: { kkid } } } = routeProps;
            if (!kkid) {
              return;
            }

            const title = selectedKK
              ? `Follow-up Contacts for ${selectedKK.firstName} ${selectedKK.lastName}`
              : '';

            return (
              <Fragment>
                <Link onClick={() => setSelectedKK(null)} className={'followup-admin-link'} to='/sampark/admin/followupKK'><img src={icon_arrow_left} /></Link>
                <FollowUpKaryakartaTab
                  title={title}
                  type='follow-up-kk-contacts'
                  items={followUpContacts}
                  stage={contactsStage}
                  emptyMessage='No follow-up contacts have been assigned for this karyakarta'
                  onModalOpen={setKaryakartaForModal}
                />
              </Fragment>
            );
          }}
        />
        <Redirect to='/sampark/admin/followupKK' />
      </Switch>
    );
  };

  const renderDesktopContainer = () => {
    return (
      <Fragment>
        <FollowUpKaryakartaTab
          title='Sampark Sevako in My Area'
          type='follow-up-kk-admins' // need to use Constants
          items={followUpKKAdmins}
          onClick={setSelectedKK} // action to setSelectedKKId
          stage={stage}
          emptyMessage='No Sampark Sevako in your area'
          selectedItem={selectedKK}
        />
        <Route
          path='/sampark/admin/followupKK/:kkid'
          render={(routeProps) => {
            const { match: { params: { kkid } } } = routeProps;
            if (!kkid) {
              return;
            }

            if (isError(contactsStage)) {
              return 'Error loading contacts for selected karyakarta.';
            }

            const title = selectedKK
              ? `Follow-up Contacts for ${selectedKK.firstName} ${selectedKK.lastName}`
              : '';

            return (
              <FollowUpKaryakartaTab
                title={title}
                stage={contactsStage}
                emptyMessage='No follow-up contacts have been assigned for this karyakarta'
                type='follow-up-kk-contacts'
                items={followUpContacts}
                onModalOpen={setKaryakartaForModal} // action to open modal
                onMenuClick={handleMenuOpen}
              />
            );
          }}
        />
        <Modal
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
          open={openKaryakartaModal}
          onClose={() => {}}>
          <div className={'followup-admin-modal-style'} >
            <a className='contact-form-dismiss' onClick={(e) => { e.preventDefault(); setKaryakartaForModal(null); }}>
              <img
                aria-label='dismiss'
                src={icon_close} />
            </a>
            <FollowUpDetails followUpIdProps={karyakartaForModal ? karyakartaForModal._id : null}
              useRoutes={false}
            ></FollowUpDetails>
          </div>
        </Modal>
      </Fragment>
    );
  };

  const handleMenuClose = (value) => {
    setAnchorEl(null);
    switch (value) {
      case 'change-follow-up':
        setOpenFollowUpModal(true);
        break;
      case 'add-sabha-tags':
        break;
      default:
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const renderMenuItems = () => {
    const menuOptions = [
      {
        name: 'Change Follow-Up',
        value: 'change-follow-up'
      },
      {
        name: 'Add Sabha Tags',
        value: 'add-sabha-tags',
        disabled: true
      }
    ];
    return (
      <Menu
        MenuListProps={{
          disablePadding: true
        }}
        style={{marginTop: '0.5rem'}}
        anchorEl={anchorEl}
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={() => { handleMenuClose(); }}>
        {menuOptions.map((option, index) => {
          return (
            <MenuItem
              key={index}
              disabled={option.disabled}
              onClick={() => { handleMenuClose(option.value); }}>
              {option.name}
            </MenuItem>
          );
        })}
      </Menu>
    );
  };

  const renderChangeFollowupModal = () => {
    return (
      <Dialog
        className='contacts-selected-dialog'
        fullScreen={false}
        open={!!openFollowUpModal}
        aria-labelledby='responsive-dialog-title'>
        <FollowUpModal
          onClose={(data) => {
            // handleSubmitFollowUp(data);
          }}
          selectedContacts={selectedKK}
        />
      </Dialog>
    );
  };

  if (isError(stage)) {
    return (
      <div className={'followup-admin-error-container'}>
        An error has occured while loading data.
        <br />
        If problem persists, please submit a ticket using the Contact Us feature.
      </div>
    );
  }

  if (oneOf('READY', 'LOADING')(stage)) {
    return <LoadingSpinner />;
  }

  return (
    <div className='admin-data-approvals-container'>
      <div className={'followup-admin-container'}>
        {
          isMobile
            ? renderMobileContainer()
            : renderDesktopContainer()
        }
      </div>
      {renderMenuItems()}
      {renderChangeFollowupModal()}
    </div>
  );
};

FollowUpAdmin.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  followUpKKAdmins: PropTypes.arrayOf(PropTypes.object).isRequired,
  followUpContacts: PropTypes.arrayOf(PropTypes.object).isRequired,
  stage: PropTypes.symbol.isRequired,
  contactsStage: PropTypes.symbol.isRequired,
  history: PropTypes.object.isRequired
};

const mapStateToProps = ({ admin: { followUpAdmin } }) => ({
  ...followUpAdmin
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ActionCreators, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(FollowUpAdmin);
