import { FC, Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { RootState } from '../../../reducers/index.types';
import * as FORMS from '../../../constants/forms';
import { change } from 'redux-form';

import {
  CANUPDATECONTACTS,
  ISSUPERADMIN,
  USERID,
  checkUserPermission,
  getAccessObj
} from '../../../util/userPermission';
import ContactCard from '../ContactCard';
import { contactActions } from '../contacts.ducks';
import {
  StyledCardsViewContainer,
  StyledListViewContainer,
  StyledMoreLoadingContainer
} from './CardsList.styles';

type CardsListProps = {
  cardView: boolean;
  openFollowUpModal: () => void;
};

export const CardsList: FC<CardsListProps> = ({ cardView, openFollowUpModal }) => {
  const {
    contacts,
    contactsTotalPages = 0,
    currentPageNumber = 0,
    selectedContacts
  } = useSelector((state: RootState) => state.contactsData);

  const userAccess = useSelector((state: RootState) => state.userAccess);
  const canUpdateMember = checkUserPermission(userAccess, CANUPDATECONTACTS);
  const userId = getAccessObj(userAccess, USERID);
  const isSuperAdmin = checkUserPermission(userAccess, ISSUPERADMIN);

  const dispatch = useDispatch();
  //TODO check alternative to bindActionCreators
  const actions = bindActionCreators(contactActions, dispatch);

  useEffect(() => {
    dispatch(change(FORMS.FILTER_FORM, 'noReference', false));
  }, [dispatch]);

  const StyledContainer = cardView ? StyledCardsViewContainer : StyledListViewContainer;

  return (
    <Fragment>
      <StyledContainer>
        {Array.isArray(contacts) && contacts?.length
          ? contacts.map((obj) => (
              <ContactCard
                key={obj._id}
                canUpdateMember={canUpdateMember}
                contact={obj}
                isListView={!cardView}
                isSuperAdmin={isSuperAdmin}
                openFollowUpModal={openFollowUpModal}
                selectedContacts={selectedContacts ?? []}
                userId={userId}
                onSelectContact={actions.selectContact}
                onUnselectContact={actions.unselectContact}
              />
            ))
          : null}
      </StyledContainer>
      {contacts?.length > 0 && currentPageNumber < contactsTotalPages ? (
        <StyledMoreLoadingContainer id={'contact-card-scroll'}>
          Loading contacts ....
        </StyledMoreLoadingContainer>
      ) : null}
    </Fragment>
  );
};
