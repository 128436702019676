import { MembersType } from '../types';

export function isContactInvited(contact: MembersType) {
  const { invitationStatus } = contact;
  if (invitationStatus && invitationStatus.length) {
    const foundSampark = invitationStatus.find((app) => app.appname === 'sampark');
    return !!foundSampark?.sent;
  }
  return false;
}
