import React, { useState } from 'react';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { Tooltip } from '@material-ui/core';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { openModal } from '../../actions/common.actions';
import {
  initializeContactForm,
  initializeReadOnlyContactForm
} from '../../actions/contactForm.actions';
import icon_iphone_x from '../../assets/icons/icon-iphone-x.svg';
import icon_address from '../../assets/icons/icon_address.svg';
import icon_checked_large from '../../assets/icons/icon_checked_large.svg';
import icon_close_small from '../../assets/icons/icon_close_small.svg';
import icon_email from '../../assets/icons/icon_email.svg';
import icon_follow_up from '../../assets/icons/icon_follow_up.svg';
import icon_phone from '../../assets/icons/icon_phone.svg';
import icon_tag_active from '../../assets/icons/icon_tag_active.svg';
import icon_tag_inactive from '../../assets/icons/icon_tag_inactive.svg';
import icon_unchecked_large from '../../assets/icons/icon_unchecked_large.svg';
import { AvatarWrapper } from '../../components/Avatar/AvatarWrapper';
import Tag from '../../components/Tag';
import { HoverImage } from '../../components/hover-image/HoverImage';
import ContactCardMenu from '../../components/menus/ContactCardMenu';
import * as MODAL_COMPONENTS from '../../constants/modals';
import { MembersType } from '../../types';
import formatPhoneNumber from '../../util/formatPhoneNumber';
import Tags from '../../v2/components/tags';

type ContactCardProps = {
  canUpdateMember?: boolean;
  contact: MembersType;
  isListView: boolean;
  isSuperAdmin: boolean;
  onSelectContact: (contact: MembersType) => void;
  onUnselectContact: (contact: MembersType) => void;
  openFollowUpModal: () => void;
  selectedContacts: Array<MembersType>;
  userId: string;
};

const ContactCard = ({
  canUpdateMember,
  contact,
  isListView,
  isSuperAdmin,
  onSelectContact,
  onUnselectContact,
  openFollowUpModal,
  selectedContacts
}: ContactCardProps) => {
  const dispatch = useDispatch();
  const actions = bindActionCreators(
    { openModal, initializeContactForm, initializeReadOnlyContactForm },
    dispatch
  );
  const [displayTags, setDisplayTags] = useState<boolean>(false);
  const handleCardClick = (contact: MembersType) => {
    if (!contact.selected) {
      onSelectContact(contact);
    } else {
      onUnselectContact(contact);
    }
  };

  const handleTagClick = () => {
    setDisplayTags((current: boolean) => !current);
  };

  // any contact can be selected
  // other components should manage whether to allow actions or not
  const renderCheckbox = (
    contact: MembersType,
    selectedContacts: Array<MembersType>,
    isSuperAdmin: boolean
  ) => {
    return (
      <img
        src={contact.selected ? icon_checked_large : icon_unchecked_large}
        className={[
          'checkbox',
          !contact.selected && !isSuperAdmin && selectedContacts.length >= 10
            ? 'checkbox-disabled'
            : null
        ].join(' ')}
        onClick={handleCheckboxClick}
      />
    );
  };

  const getTagsFromContact = (contact: MembersType) => {
    const tags = [];
    if (!contact) {
      throw new Error('Cannot get tags of undefined contact');
    } else {
      if (contact.maritalStatus) {
        tags.push(contact.maritalStatus);
      }
      if (contact.muktType && contact.muktType instanceof Array && contact.muktType.length) {
        contact.muktType.forEach((type) => tags.push(type));
      }
      if (contact.profession && contact.profession.jobTitle) {
        tags.push(contact.profession.jobTitle);
      }
    }
    return tags;
  };

  const renderTags = (tags: string[]) => {
    let list: JSX.Element[] = [];
    if (tags && tags instanceof Array && tags.length) {
      list = tags.filter((t) => t).map((tag, index) => <Tag key={index} value={tag} />);
    }

    return <div className={'tag-list'}>{list}</div>;
  };

  const renderEmail = (contact: MembersType) => {
    let { contactDetails } = contact;
    if (!contactDetails) {
      contactDetails = Object.assign({});
      contactDetails.email = '';
    }
    return (
      <div className="contact-card-email">
        <img className="email-icon" src={icon_email} />
        <p title={contactDetails.email}>{contactDetails.email}</p>
      </div>
    );
  };

  const renderPhoneNumber = (contact: MembersType) => {
    const { contactDetails } = contact;
    if (contactDetails && contactDetails.phones) {
      const phones = contactDetails.phones;
      if (phones instanceof Array && phones.length) {
        const cellNumber = contactDetails.phones.find((phone) => phone.type === 'Cell') || '';
        if (
          cellNumber &&
          cellNumber.value &&
          typeof cellNumber.value === 'string' &&
          cellNumber.value.length
        ) {
          return (
            <div className="contact-card-phoneNumber">
              <img className="phone-icon" src={icon_phone} />
              <a href={`tel:${cellNumber.value}`}>{formatPhoneNumber(cellNumber.value)}</a>
            </div>
          );
        } else {
          return null;
        }
      } else {
        return null;
      }
    }
  };

  const renderAddressDetails = (contact: MembersType) => {
    const { addressDetails } = contact;

    if (!(addressDetails && addressDetails.city && addressDetails.state)) {
      return null;
    }
    return (
      <div className="contact-card-address">
        <img className="address-icon" src={icon_address} />
        <div>
          {addressDetails.address1 && <div>{addressDetails.address1},</div>}
          {addressDetails.address1 && addressDetails.address2 && (
            <div>{`${addressDetails.address2}, `}</div>
          )}
          <div className="contact-card-address-state">
            {addressDetails.city + ', '}
            {addressDetails.state}
            {addressDetails.zip && ', '}
            {addressDetails.zip && addressDetails.zip.substr(0, 5)}
          </div>
        </div>
      </div>
    );
  };

  const renderFollowUp = (contact: MembersType) => {
    /**
     * if no follow-up is assigned, show option to assign follow up
     *
     * enable the button if no contacts are selected
     * OR if only 1 contact is selected and _id matches the contact
     */
    if (!(contact.followUp && contact.followUp.length)) {
      let disabled = true;
      if (
        selectedContacts.length === 0 ||
        (selectedContacts.length === 1 && selectedContacts[0]._id === contact._id)
      ) {
        disabled = false;
      }

      return (
        <Tooltip title={disabled ? 'Another contact is selected' : 'Assign Follow-up'}>
          <img
            src={icon_follow_up}
            className={classnames('assign-follow-up-link', { disabled: disabled })}
            onClick={() => {
              if (!disabled) {
                // if contact is not selected, select him
                if (!contact.selected) {
                  onSelectContact(contact);
                }
                openFollowUpModal();
              }
            }}
          />
        </Tooltip>
      );
    }

    return contact.followUp.map((followup) => {
      const { firstName = '', lastName = '' } = followup;

      return (
        <React.Fragment key={followup._id}>
          <div className="contact-card-first-name">{`${firstName} ${lastName}`}</div>
        </React.Fragment>
      );
    });
  };

  const renderApp = (contact: MembersType) => {
    if (contact.isSambandhUser) {
      return (
        <Tooltip placement="top" title="This is a Sambandh user">
          <img src={icon_iphone_x} />
        </Tooltip>
      );
    }
  };

  const handleCheckboxClick = () => {
    return handleCardClick(contact);
  };

  const handleDetailsClick = () => {
    actions.initializeContactForm(contact);
    actions.openModal(MODAL_COMPONENTS.CONTACT_INFO_READ_ONLY, {
      maxWidth: 'sm'
    });
  };

  const getTags = (member: MembersType) => {
    const data = [];
    const tags = member.tags || {};
    const sabhaTags = tags.followUp || [];
    const interests = member.interests || [];
    const muktType = member.muktType || [];
    const adminAttributes = tags.adminAttributes || [];

    data.push({
      category: 'Sabha Type',
      tags: sabhaTags
    });

    data.push({
      category: 'Mukt type',
      tags: muktType
    });

    data.push({
      category: 'Interests',
      tags: interests
    });

    data.push({
      category: 'Admin Attributes',
      tags: adminAttributes
    });

    return data;
  };

  const getWorkshopStatusClassName = (status?: string) => {
    if (status === 'invited') {
      return 'contact-card-workshop-invited';
    } else if (status === 'registered') {
      return 'contact-card-workshop-registered';
    } else if (status === 'paid') {
      return 'contact-card-workshop-paid';
    } else if (status === 'not attending') {
      return 'contact-card-workshop-not-attending';
    }

    return '';
  };

  const inviteToWorkshopFlag = useFeatureIsOn('sampark.invite-to-workshop');

  const city =
    contact.addressDetails && contact.addressDetails.city ? contact.addressDetails.city : '';
  const state =
    contact.addressDetails && contact.addressDetails.state
      ? contact.addressDetails.state.toUpperCase()
      : '';
  const cityState = city ? `${city}, ${state}` : `${state}`;

  return (
    <div className="contact-card-container">
      <div className={isListView ? 'contact-view-list' : 'contact-view-card'}>
        <div
          className={[
            'contact-card',
            inviteToWorkshopFlag ? 'contact-card-tall' : null,
            displayTags ? 'contact-card-opacity' : null
          ].join(' ')}
        >
          <div className="left-group">
            {renderCheckbox(contact, selectedContacts, isSuperAdmin)}
            {inviteToWorkshopFlag ? (
              <div className={'contact-card-workshop-status'}>
                <span className={getWorkshopStatusClassName(contact.workshopInvitationStatus)}>
                  {contact.workshopInvitationStatus}
                </span>
              </div>
            ) : null}
            {canUpdateMember ? (
              <div className="contact-card-overflow">
                <ContactCardMenu contact={contact} />
              </div>
            ) : null}
            <div className="contact-card-avatar">
              <AvatarWrapper
                size={isListView ? '36' : '64'}
                classes={['contact-avatar']}
                contact={contact}
              />
            </div>
            <div className="basic-info">
              <div className="contact-card-first-name">{`${contact.firstName} ${contact.lastName}`}</div>
              <div className="contact-card-city-state">{`${cityState}`}</div>
            </div>
            <div className={`nickname`}>{contact.nickName ? contact.nickName : ' '}</div>
            <div className="contact-details">
              {renderPhoneNumber(contact)}
              {renderEmail(contact)}
              {renderAddressDetails(contact)}
            </div>
            <div className="follow-up">{renderFollowUp(contact)}</div>
            {inviteToWorkshopFlag ? (
              <div className="workshop-invitation-status">
                <span className={getWorkshopStatusClassName(contact.workshopInvitationStatus)}>
                  {contact.workshopInvitationStatus}
                </span>
              </div>
            ) : (
              <div className="tags">
                <Tags data={getTags(contact)} />
              </div>
            )}
            <div className="app">{renderApp(contact)}</div>
            <div className="contact-card-actions">
              <div className="contact-card-tag">
                <HoverImage
                  src={icon_tag_inactive}
                  hoverSrc={icon_tag_active}
                  onClick={handleTagClick}
                />
              </div>
              {contact.yogId ? <div className="contact-card-yog-badge">Yog</div> : null}
              <div className="stacked-actions-list">
                <a className={'link list-details'} onClick={handleDetailsClick}>
                  Details
                </a>
              </div>
              <a className={'link card-details-link'} onClick={handleDetailsClick}>
                Details
              </a>
              {canUpdateMember ? (
                <div className="contact-card-overflow-list">
                  <ContactCardMenu contact={contact} displayHorizontal />
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className={displayTags ? 'contact-card-tags' : 'contact-card-tags-hidden'}>
          <div className={'contact-card-tags-title'}>Tags</div>
          <img className="icon-close" src={icon_close_small} onClick={handleTagClick} />
          {renderTags(getTagsFromContact(contact))}
        </div>
      </div>
    </div>
  );
};

export default ContactCard;
