import { useEffect, useRef, useState } from 'react';

type elementType = {
  root?: Element;
  rootMargin: string;
  threshold: number;
};

export default (callback: (entries: any) => void, element: elementType) => {
  const [entry, updateEntry] = useState({});
  const [node, setNode] = useState(null);
  const { root = null, rootMargin, threshold = 0 } = element;

  const observer = useRef(
    new IntersectionObserver(
      (entries) => {
        callback(entries);
        updateEntry(entries[0]);
      },
      { root, rootMargin, threshold }
    )
  );

  useEffect(() => {
    const currentNode = node;
    const currentObserver = observer.current;

    if (currentNode) {
      currentObserver.observe(currentNode);
    }

    return () => {
      if (currentNode) {
        currentObserver.unobserve(currentNode);
      }
    };
  }, [node]);

  return [setNode, entry];
};
