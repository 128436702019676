import { Dispatch } from 'react';
import { MembersType } from '../../types';
import Stage from '../../util/stage';
import { generateTypes, makeAction } from '../../util/actions';
import {
  getContactsAPI,
  getContactByIdAPI,
  getExportDataAPI,
  getContactOptionsAPI,
  getFilterCitySuggestionAPI,
  uploadContactAvatarAPI,
  cancelInvitationAPI,
  deleteContactsAPI,
  inviteContactToSamparkAPI,
  getAudiencesListAPI
} from '../../apis/contactAPIs';
import { commonActions } from '../../constants/actions';
import { sortOptions } from '../../constants/sortOptions';
import {
  CONTACT_DATA_ERROR,
  CONTACT_PAGINATION_ERROR,
  CONTACT_OPTIONS_ERROR,
  CONTACT_AVATAR_UPLOAD_ERROR,
  CANCEL_INVITE_ERROR,
  CONTACT_DELETED_CONTACTS_ERROR,
  CONTACT_DELETED_ERROR,
  INVITE_CONTACT_ERROR,
  // SUCCESS MESSAGES
  SAMPARK_INVITATION_CANCELLED_SUCCESS,
  CONTACT_DELETED_SUCCESS,
  INVITE_CONTACT_SUCCESS
} from '../../constants/toasterMessages';
import { RootState } from '../../reducers/index.types';
import { getInvitationStatusForMembers } from '../../apis/invitationAPIs';
import { mapObjectToOptionValueArray } from '../../util/contactUtils';

const TYPES = generateTypes(
  'SHOW_LOADER',
  'SET_CONTACTS_LIST',
  'UPDATE_CONTACTS_LIST',
  'APPEND_CONTACTS_LIST',
  'RESET_CONTACTS_LIST',
  'HIDE_LOADER',
  'SELECT_CONTACT',
  'UNSELECT_CONTACT',
  'CLEAR_SELECTED_CONTACTS',
  'UPDATE_SEARCH_STATE',
  'DOWNLOAD_CONTACT_REPORT',
  'UPDATE_SORT_VALUE',
  'SHOW_HIDE_TOASTER',
  'UPDATE_CITY_FILTER_VALUE',
  'UPDATE_STATE_FILTER_VALUE',
  'GET_CONTACT_OPTIONS',
  'GET_FILTER_CITY_SUGGESTIONS',
  'CLEAR_FILTER_CITY_SUGGESTIONS',

  'UPLOAD_CONTACT_AVATAR',
  'SELECT_ALL_CONTACTS'
);

const Actions = Object.freeze({
  set: (data: any) => makeAction(TYPES.SET_CONTACTS_LIST, { data }),
  append: (data: any) => makeAction(TYPES.APPEND_CONTACTS_LIST, { data }),
  update: (data: any) => makeAction(TYPES.UPDATE_CONTACTS_LIST, { data }),
  reset: () => makeAction(TYPES.RESET_CONTACTS_LIST),
  setContactOptions: (data: any) => makeAction(TYPES.GET_CONTACT_OPTIONS, { data }),
  hideLoader: () => makeAction(TYPES.HIDE_LOADER),
  showLoader: () => makeAction(TYPES.SHOW_LOADER),
  setSelectContact: (data: any) => makeAction(TYPES.SELECT_CONTACT, { data }),
  setUnselectContact: (data: any) => makeAction(TYPES.UNSELECT_CONTACT, { data }),
  resetSelectContact: (data: any) => makeAction(TYPES.CLEAR_SELECTED_CONTACTS, { data }),
  setSearchState: (data: any) => makeAction(TYPES.UPDATE_SEARCH_STATE, { data }),
  downloadContactReport: (data: any) => makeAction(TYPES.DOWNLOAD_CONTACT_REPORT, { data }),
  setSortValue: (data: any) => makeAction(TYPES.UPDATE_SORT_VALUE, { data }),
  showHideToaster: (data: any) => makeAction(TYPES.SHOW_HIDE_TOASTER, { data }),
  setCityFilter: (data: any) => makeAction(TYPES.UPDATE_CITY_FILTER_VALUE, { data }),
  setStateFilter: (data: any) => makeAction(TYPES.UPDATE_STATE_FILTER_VALUE, { data }),
  getFilterCitySuggestions: (data: any) => makeAction(TYPES.GET_FILTER_CITY_SUGGESTIONS, { data }),
  clearFilterCitySuggestions: () => makeAction(TYPES.CLEAR_FILTER_CITY_SUGGESTIONS),
  uploadContactAvatar: (data: any) => makeAction(TYPES.UPLOAD_CONTACT_AVATAR, { data })
});

const getContacts = async (
  dispatch: Dispatch<any>,
  getState: () => RootState,
  append = false,
  search: string,
  inviteToWorkshopFlag?: boolean,
  isReadOnly?: boolean
) => {
  const currentState = getState();
  const { contactsData } = currentState;

  const {
    currentPageNumber,
    sortValue: contactSortValue,
    emailType: contactEmailType,
    filteredCity,
    filteredState
  } = contactsData;

  // These filters come from the contacts reducer as they have been moved out
  // of the filter panel
  const cityStateFilters = {
    city: filteredCity,
    state: filteredState
  };

  let attributes = [];
  if (currentState?.form?.filterForm?.values) {
    attributes = currentState?.form?.filterForm?.values?.attributes?.map((attr: any) => attr.value);
  }

  let muktType = [];
  if (currentState?.form?.filterForm?.values) {
    muktType = currentState?.form?.filterForm?.values?.muktType?.map((type: any) => type.value);
  }
  let adminAttributes = [];
  if (currentState?.form?.filterForm?.values) {
    adminAttributes = currentState?.form?.filterForm?.values?.adminAttributes?.map(
      (type: any) => type.value
    );
  }
  let audiences = [];
  if (currentState?.form?.filterForm?.values) {
    audiences = currentState?.form?.filterForm?.values?.audiences?.map((type: any) => type.value);
  }

  const contactFilterValues = currentState.form.filterForm
    ? {
        ...cityStateFilters,
        ...currentState.form.filterForm.values,
        attributes,
        muktType,
        adminAttributes,
        audiences
      }
    : cityStateFilters;

  //Show Loader when calling getContactsAPI call
  dispatch(Actions.showLoader());

  const pageNumber = append ? currentPageNumber + 1 : 1;

  const contactsResponse = await getContactsAPI(
    search,
    pageNumber,
    contactFilterValues,
    contactSortValue,
    contactEmailType
  );
  if (contactsResponse && contactsResponse.docs && contactsResponse.docs instanceof Array) {
    let invitationStatusForMembers: { memberId: string; status: string }[] = [];
    if (inviteToWorkshopFlag && !isReadOnly) {
      const memberIds = contactsResponse.docs.map((contact: MembersType) => contact._id);
      invitationStatusForMembers = await getInvitationStatusForMembers(memberIds);
    }

    const totalPages = contactsResponse.pages;
    const members = contactsResponse.docs.map((contact: MembersType) => {
      contact.selected = false;
      // If feature flag is on, add workshopInvitationStatus property to member
      if (inviteToWorkshopFlag && invitationStatusForMembers?.length) {
        const foundInvitationStatusForContact = invitationStatusForMembers.find(
          (member: { memberId: string; status: string }) => member.memberId === contact._id
        );
        if (foundInvitationStatusForContact) {
          contact.workshopInvitationStatus = foundInvitationStatusForContact.status;
        }
      }
      return contact;
    });
    const totalContacts = contactsResponse.total;
    const data = { members, totalPages, totalContacts, pageNumber };
    return append ? dispatch(Actions.append(data)) : dispatch(Actions.set(data));
  } else {
    dispatch(Actions.hideLoader());
    return dispatch({
      type: commonActions.SHOW_HIDE_TOASTER,
      value: {
        displayToaster: true,
        message: append ? CONTACT_PAGINATION_ERROR : CONTACT_DATA_ERROR,
        type: commonActions.ERROR_TOASTER
      }
    });
  }
};

const getContactById = async (dispatch: Dispatch<any>, getState: () => RootState, id: string) => {
  const currentState = getState();
  const contactsReducerData = currentState.contactsData;
  const contacts = contactsReducerData.contacts;
  const foundContactIndex = contacts.findIndex((member: MembersType) => member.id === id);
  if (foundContactIndex !== -1) {
    const fetchedContact = await getContactByIdAPI(id);
    if (!fetchedContact.error) {
      contacts[foundContactIndex] = fetchedContact;
      return dispatch(Actions.update(contacts));
    }
  }
};

const fetchNextContactsPage =
  (inviteToWorkshopFlag: boolean, isReadOnly = true) =>
  (dispatch: Dispatch<any>, getState: () => RootState) => {
    const currentState = getState();
    const { contactsData } = currentState;
    return getContacts(
      dispatch,
      getState,
      true,
      contactsData.searchValue,
      inviteToWorkshopFlag,
      isReadOnly
    );
  };

const fetchContactsList =
  ({
    searchValue,
    append = false,
    inviteToWorkshopFlag,
    isReadOnly = true
  }: {
    searchValue: string;
    append?: boolean;
    inviteToWorkshopFlag?: boolean;
    isReadOnly?: boolean;
  }) =>
  (dispatch: Dispatch<any>, getState: () => RootState) => {
    return getContacts(dispatch, getState, append, searchValue, inviteToWorkshopFlag, isReadOnly);
  };

const fetchContactById = (id: string) => (dispatch: Dispatch<any>, getState: () => RootState) => {
  return getContactById(dispatch, getState, id);
};

const resetContactsList = () => (dispatch: Dispatch<any>) => dispatch(Actions.reset());

const selectContact =
  (contact: MembersType) => (dispatch: Dispatch<any>, getState: () => RootState) => {
    const currentState = getState();
    const contactsReducerData = currentState.contactsData;
    const contacts = contactsReducerData.contacts;
    // TODO: Review indexOf
    const foundSelectedContactIndex = contacts.findIndex(
      (member: MembersType) => member.id === contact.id
    );
    if (foundSelectedContactIndex !== -1) {
      const newSelectedContact = JSON.parse(JSON.stringify(contact));
      newSelectedContact.selected = true;
      contacts[foundSelectedContactIndex] = newSelectedContact;
      const data = { contacts, selectedContact: contact };
      return dispatch(Actions.setSelectContact(data));
    }
  };

const unselectContact =
  (contact: MembersType) => (dispatch: Dispatch<any>, getState: () => RootState) => {
    const currentState = getState();
    const contactsReducerData = currentState.contactsData;
    const contacts: MembersType[] = contactsReducerData.contacts;
    const selectedContacts = contactsReducerData.selectedContacts;

    const selectedContactToRemoveIndex = selectedContacts.findIndex(
      (selectedContact: MembersType) => selectedContact.id === contact.id
    );
    if (selectedContactToRemoveIndex !== -1) {
      selectedContacts.splice(selectedContactToRemoveIndex, 1);
      const updatedSelectedContacts = Array.from(new Set(selectedContacts));

      // Update the contact in contactsData with a selected prop of false
      const foundContactIndexFromContacts = contacts.findIndex((c) => c.id === contact.id);
      if (foundContactIndexFromContacts !== -1) {
        const unselectedContact = JSON.parse(JSON.stringify(contact));
        unselectedContact.selected = false;
        contacts[foundContactIndexFromContacts] = unselectedContact;
        return dispatch(Actions.setUnselectContact({ contacts, updatedSelectedContacts }));
      }
    }
  };

const clearSelectedContacts = () => (dispatch: Dispatch<any>, getState: () => RootState) => {
  const currentState = getState();
  const contactsReducerData = currentState.contactsData;
  const contacts = contactsReducerData.contacts;

  const updatedSelectedContacts = contacts.map((contact: MembersType) => {
    const newContactObject = JSON.parse(JSON.stringify(contact));
    newContactObject.selected = false;
    return newContactObject;
  });
  return dispatch(Actions.resetSelectContact(updatedSelectedContacts));
};

const updateSearchState = (value: string) => (dispatch: Dispatch<any>) => {
  return dispatch(Actions.setSearchState(value));
};

const getExportData = () => async (dispatch: Dispatch<any>, getState: () => RootState) => {
  const currentState = getState();
  const contactFilterValues = currentState.form.filterForm
    ? currentState.form.filterForm.values
    : {};
  const { filteredCity: city, filteredState: state } = currentState.contactsData;

  // If we have city filter, ignore state as city will include state, otherwise use state
  let exportFilterValues: any;
  if (city) {
    exportFilterValues = Object.assign({}, { ...contactFilterValues, city });
  } else {
    exportFilterValues = Object.assign({}, { ...contactFilterValues, state });
  }
  if (exportFilterValues?.audiences) {
    exportFilterValues.audiences = exportFilterValues.audiences.map(
      (audience: any) => audience.value
    );
  }
  getExportDataAPI(exportFilterValues).then((data) => {
    dispatch(Actions.downloadContactReport(data));
  });
};

const updateSortValue = (value: string) => (dispatch: Dispatch<any>) => {
  return dispatch(Actions.setSortValue(value));
};

const showSuccessToaster = (toasterMessage: string) => (dispatch: Dispatch<any>) => {
  return dispatch(
    Actions.showHideToaster({
      displayToaster: true,
      message: toasterMessage,
      type: commonActions.SUCCESS_TOASTER
    })
  );
};

const showErrorToaster = (toasterMessage: string) => (dispatch: Dispatch<any>) => {
  return dispatch(
    Actions.showHideToaster({
      displayToaster: true,
      message: toasterMessage,
      type: commonActions.ERROR_TOASTER
    })
  );
};

const updateFilteredCity = (value: string) => (dispatch: Dispatch<any>) => {
  return dispatch(Actions.setCityFilter(value));
};

const updateFilteredState = (value: string) => (dispatch: Dispatch<any>) => {
  return dispatch(Actions.setStateFilter(value));
};

const getContactOptions = () => async (dispatch: Dispatch<any>) => {
  const contactOptions = await getContactOptionsAPI();
  if (contactOptions) {
    const audiencesList = await getAudiencesListAPI();
    return dispatch(Actions.setContactOptions({ ...contactOptions, audiencesList }));
  } else {
    return dispatch({
      type: commonActions.SHOW_HIDE_TOASTER,
      value: {
        displayToaster: true,
        message: CONTACT_OPTIONS_ERROR,
        type: commonActions.ERROR_TOASTER
      }
    });
  }
};

const getFilterCitySuggestions =
  (cityInputValue: string) => async (dispatch: Dispatch<any>, getState: () => RootState) => {
    const filteredState = getState().contactsData.filteredState;
    const filterSuggestion = await getFilterCitySuggestionAPI(cityInputValue);
    if (filterSuggestion && !filterSuggestion.error && filterSuggestion.cities) {
      let { cities } = filterSuggestion;
      // If a state is selected, then filter the list of suggestions based on the selected state
      if (filteredState) {
        cities = filterSuggestion.cities.filter(
          (suggestion: any) => suggestion.state === filteredState
        );
      }
      return dispatch(Actions.getFilterCitySuggestions(cities));
    }
  };

const clearFilterCitySuggestions = () => (dispatch: Dispatch<any>) => {
  return dispatch(Actions.clearFilterCitySuggestions());
};

const uploadContactAvatar =
  (formName: string, contactID = null) =>
  async (dispatch: Dispatch<any>, getState: () => RootState) => {
    const currentState = getState();
    const form = currentState.form;
    let formDataObject;

    if (form) {
      const formData = form[formName];
      const formValues = formData.values;

      if (formValues) {
        let userID;
        if (contactID) {
          userID = contactID;
        } else {
          userID = formValues.id;
        }
        const profileImage = formValues.profileImageUrl;

        if (profileImage) {
          formDataObject = new FormData();
          formDataObject.append('profileImage', profileImage, profileImage.name);

          const uploadContactAvatarResults = await uploadContactAvatarAPI(formDataObject, userID);
          if (!uploadContactAvatarResults.error) {
            dispatch(Actions.uploadContactAvatar(uploadContactAvatarResults));
          } else {
            dispatch({
              type: commonActions.SHOW_HIDE_TOASTER,
              value: {
                displayToaster: true,
                message: CONTACT_AVATAR_UPLOAD_ERROR,
                type: commonActions.ERROR_TOASTER
              }
            });
          }
          return uploadContactAvatarResults;
        }
      }
    }
  };

// TODO: Review the dispatch update_contacts in here
const cancelInvitation = (contact: MembersType) => {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    dispatch(Actions.showLoader());

    const cancelInvitationResponse = await cancelInvitationAPI(contact);
    if (!cancelInvitationResponse || cancelInvitationResponse.error) {
      dispatch(Actions.hideLoader());
      dispatch({
        type: commonActions.SHOW_HIDE_TOASTER,
        value: {
          displayToaster: true,
          message: CANCEL_INVITE_ERROR,
          type: commonActions.ERROR_TOASTER
        }
      });
    } else {
      const state = getState();
      if (state) {
        const contactsData = state.contactsData;
        if (contactsData) {
          const contacts = contactsData.contacts;
          if (contacts && contacts instanceof Array) {
            const foundUserByEmail = contacts.find(
              (contactFromState) =>
                contactFromState.contactDetails.email === contact.contactDetails.email
            );
            if (foundUserByEmail) {
              foundUserByEmail.invitationStatus = [
                {
                  appname: 'sampark',
                  sent: false
                }
              ];
              // set updated contacts
              dispatch(Actions.update(contacts));
              dispatch(Actions.hideLoader());
              dispatch({
                type: commonActions.SHOW_HIDE_TOASTER,
                value: {
                  displayToaster: true,
                  message: SAMPARK_INVITATION_CANCELLED_SUCCESS,
                  type: commonActions.SUCCESS_TOASTER
                }
              });
            }
          }
        }
      }
    }
  };
};

const deleteSelectedContacts = (selectedContacts: MembersType[]) => {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    dispatch(Actions.showLoader());
    const deletedContactsResponse = await deleteContactsAPI(selectedContacts);
    if (deletedContactsResponse === 200) {
      // If the contacts were deleted successfully, we need to call the getContactsAPI to update the list in the contacts view
      // First, we will reset the current page number to 1, and then call the API
      dispatch(Actions.reset());
      const currentState = getState();
      const contactsReducerData = currentState.contactsData;
      const searchValue = contactsReducerData.searchValue;
      const currentPageNumber = contactsReducerData.currentPageNumber;

      const contactsData = await getContactsAPI(searchValue, currentPageNumber);

      dispatch(Actions.hideLoader());

      if (contactsData && contactsData.docs && contactsData.docs instanceof Array) {
        const members = contactsData.docs;
        const totalPages = contactsData.pages;
        const totalContacts = contactsData.total;
        dispatch(Actions.resetSelectContact(members));
        dispatch(
          Actions.set({ members, totalPages, totalContacts, pageNumber: currentPageNumber })
        );
        dispatch({
          type: commonActions.SHOW_HIDE_TOASTER,
          value: {
            displayToaster: true,
            message: CONTACT_DELETED_SUCCESS,
            type: commonActions.SUCCESS_TOASTER
          }
        });
      } else {
        dispatch({
          type: commonActions.SHOW_HIDE_TOASTER,
          value: {
            displayToaster: true,
            message: CONTACT_DELETED_CONTACTS_ERROR,
            type: commonActions.ERROR_TOASTER
          }
        });
      }
    } else {
      dispatch(Actions.hideLoader());
      dispatch({
        type: commonActions.SHOW_HIDE_TOASTER,
        value: { message: CONTACT_DELETED_ERROR, type: commonActions.ERROR_TOASTER }
      });
    }
  };
};

const inviteContactToSampark = (memberId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    dispatch(Actions.showLoader());
    const inviteContactToSamparkResponse = await inviteContactToSamparkAPI(memberId);
    if (!inviteContactToSamparkResponse || inviteContactToSamparkResponse.error) {
      dispatch(Actions.hideLoader());

      let errorMessage;
      if (
        inviteContactToSamparkResponse.error &&
        typeof inviteContactToSamparkResponse.error === 'string'
      ) {
        errorMessage = inviteContactToSamparkResponse.error;
      } else {
        errorMessage = INVITE_CONTACT_ERROR;
      }
      dispatch({
        type: commonActions.SHOW_HIDE_TOASTER,
        value: { displayToaster: true, message: errorMessage, type: commonActions.ERROR_TOASTER }
      });
    } else {
      const state = getState();
      if (state) {
        const contactsData = state.contactsData;
        if (contactsData) {
          const contacts = contactsData.contacts;
          if (contacts && contacts.length) {
            const foundUserById = contacts.find((contact: MembersType) => contact.id === memberId);
            if (foundUserById) {
              foundUserById.invitationStatus = [
                {
                  appname: 'sampark',
                  sent: true
                }
              ];
              dispatch(Actions.update(contacts));
              dispatch(Actions.hideLoader());
              dispatch({
                type: commonActions.SHOW_HIDE_TOASTER,
                value: {
                  displayToaster: true,
                  message: INVITE_CONTACT_SUCCESS,
                  type: commonActions.SUCCESS_TOASTER
                }
              });
            }
          }
        }
      }
    }
  };
};

export interface IContactsState {
  stage: typeof Stage;
  contacts: Array<any>;
  contactOptions: {
    maritalStatus: Array<any>;
    muktTypes: Array<any>;
    phoneTypes: Array<any>;
  };
  allContactsSelected: boolean;
  selectedContacts: Array<any>;
  contactsTotalPages: number;
  contactsTotalNumber: number;
  currentPageNumber: number;
  searchValue: string;
  sortValue: string;
  emailType: string;
  filteredCity: string;
  filteredState: string;
  filterCitySuggestions: Array<any>;
}

const initialState: IContactsState = Object.freeze({
  stage: Stage.READY,
  contacts: [],
  contactOptions: {
    maritalStatus: [],
    muktTypes: [],
    phoneTypes: [],
    mandals: []
  },
  allContactsSelected: false,
  selectedContacts: [],
  contactsTotalPages: 0,
  contactsTotalNumber: 0,
  currentPageNumber: 1,
  searchValue: '',
  sortValue: sortOptions[1].value,
  emailType: '',
  filteredCity: '',
  filteredState: '',
  filterCitySuggestions: []
});

const reducer = (
  state = initialState,
  {
    type,
    payload
  }: {
    type: string | any;
    payload: any;
  }
) => {
  switch (type) {
    //when fetching or updating
    case TYPES.SHOW_LOADER:
      return {
        ...state,
        stage: Stage.LOADING
      };
    case TYPES.SET_CONTACTS_LIST:
      return {
        ...state,
        stage: Stage.LOADED,
        contacts: payload.data.members,
        contactsTotalPages: payload.data.totalPages,
        contactsTotalNumber: payload.data.totalContacts,
        currentPageNumber: payload.data.pageNumber,
        allContactsSelected: false,
        selectedContacts: []
      };
    case TYPES.APPEND_CONTACTS_LIST:
      return {
        ...state,
        stage: Stage.LOADED,
        contacts: [...state.contacts, ...payload.data.members],
        contactsTotalPages: payload.data.totalPages,
        contactsTotalNumber: payload.data.totalContacts,
        currentPageNumber: payload.data.pageNumber
      };
    case TYPES.UPDATE_CONTACTS_LIST:
      return {
        ...state,
        stage: Stage.LOADED,
        contacts: payload.data
      };
    case TYPES.HIDE_LOADER:
      return {
        ...state,
        stage: Stage.LOADED
      };
    case TYPES.RESET_CONTACTS_LIST:
      return {
        ...state,
        contacts: [],
        contactsTotalPages: 0,
        contactsTotalNumber: 0,
        currentPageNumber: 1,
        allContactsSelected: false,
        selectedContacts: []
      };
    case TYPES.GET_CONTACT_OPTIONS:
      return {
        ...state,
        contactOptions: payload.data
      };
    case TYPES.SELECT_CONTACT:
      return {
        ...state,
        contacts: payload.data.contacts,
        selectedContacts: [...state.selectedContacts, payload.data.selectedContact]
      };
    case TYPES.UNSELECT_CONTACT:
      return {
        ...state,
        contacts: payload.data.contacts,
        selectedContacts: payload.data.updatedSelectedContacts
      };
    case TYPES.CLEAR_SELECTED_CONTACTS:
      return {
        ...state,
        allContactsSelected: false,
        contacts: payload.data,
        selectedContacts: []
      };
    case TYPES.UPDATE_SEARCH_STATE:
      return {
        ...state,
        searchValue: payload.data
      };
    case TYPES.DOWNLOAD_CONTACT_REPORT:
      return {
        ...state,
        downloadReport: payload.data
      };
    case TYPES.UPDATE_SORT_VALUE:
      return {
        ...state,
        sortValue: payload.data
      };
    case TYPES.UPDATE_CITY_FILTER_VALUE:
      return {
        ...state,
        filteredCity: payload.data
      };
    case TYPES.UPDATE_STATE_FILTER_VALUE:
      return {
        ...state,
        filteredState: payload.data
      };
    case TYPES.GET_FILTER_CITY_SUGGESTIONS:
      return {
        ...state,
        filterCitySuggestions: payload.data
      };
    case TYPES.CLEAR_FILTER_CITY_SUGGESTIONS:
      return {
        ...state,
        filterCitySuggestions: []
      };
    // TODO: Review upload_contact_avatar
    // action.value.uploadContactAvatarResults
    case TYPES.UPLOAD_CONTACT_AVATAR:
      return { ...state };
    default:
      return state;
  }
};

export const contactActions = {
  cancelInvitation,
  clearFilterCitySuggestions,
  clearSelectedContacts,
  deleteSelectedContacts,
  fetchContactById,
  fetchContactsList,
  fetchNextContactsPage,
  getContactOptions,
  getExportData,
  getFilterCitySuggestions,
  inviteContactToSampark,
  resetContactsList,
  selectContact,
  showErrorToaster,
  showSuccessToaster,
  unselectContact,
  updateFilteredCity,
  updateFilteredState,
  updateSearchState,
  updateSortValue,
  uploadContactAvatar
};

export const selectMandalListByGender = (state: RootState) => {
  const { contactsData, userAccess } = state;
  const {
    isSuperAdmin,
    userAccount: { gender }
  } = userAccess;
  const {
    contactOptions: { mandals }
  } = contactsData;

  if (!isSuperAdmin) {
    const mandalsByGender = mapObjectToOptionValueArray(mandals[gender.toLowerCase()]);
    return mandalsByGender;
  }

  const allMandals = ['male', 'female'].reduce((acc, gender) => {
    return {
      ...acc,
      ...mandals[gender]
    };
  }, {});
  return mapObjectToOptionValueArray(allMandals);
};

export default reducer;
