import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import reduxThunk from 'redux-thunk';
import reducers from '../reducers';
import { RootState } from '../reducers/index.types';

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: reducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(reduxThunk)
});

export default store;

export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
