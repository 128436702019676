import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  destroy,
  isValid,
  isDirty,
  formValueSelector
} from 'redux-form';
import PropTypes from 'prop-types';
import {
  DialogTitle,
} from '@material-ui/core';
import BasicInfoSection from './sections/BasicInfoSection';
import PersonalInfoSection from './sections/PersonalInfoSection';
import PanelFooter from '../PanelFooter';
import * as FORMS from '../../constants/forms';
import ContactCardMenu from '../menus/ContactCardMenu';
import { closeModal } from '../../actions/common.actions';
import { contactActions } from '../../views/Contacts/contacts.ducks';
import icon_close from '../../assets/icons/icon_cross.svg';
import { CopyIcon } from '../CopyIcon';
import { FOLLOW_UP } from '../../constants/routes';

class ContactPanel extends Component {
  constructor(props) {
    super(props);
  }

  componentWillUnmount() {
    this.props.destroy(this.props.myUserAccount ? FORMS.USER_PROFILE_FORM : FORMS.CONTACT_INFO);
  }

  componentDidUpdate(prevProps) {
    if (!this.props.myUserAccount) {
      if ((prevProps.formSubmitted !== this.props.formSubmitted) && this.props.formSubmitted) {
        // If contact was updated, then fetch for the individual contact's data
        const { initialValues, searchValue } = this.props;
        if (initialValues && initialValues.id) {
           // Otherwise, a new contact was created, so fetch the entire list
          if (initialValues.contactStatus === 'yog_pending') {
            this.props.getContactsData(this.props.searchValue);
          } else {
            this.props.getContactById(this.props.initialValues.id);
          }
        } else {
          // Otherwise, a new contact was created, so fetch the entire list
          // when using from a followup screen, we don't need to fetch the list.
          // For follow up user might not even have access to contacts, which will cause api to fail.
          if (!window.location.href.includes(FOLLOW_UP.value)) {
            this.props.getContactsData(this.props.searchValue);
          }
        }
      }
    }
  }

  render() {
    const { initialValues, isFormDirty, isFormValid } = this.props;
    const { updatedAt, yogId } = initialValues;
    const isUnverified = initialValues.contactStatus === "yog_pending";
    let uploadingImage = false;
    const currentProfileImageUrl = this.props.selectedValues.profileImageUrl;
    if (currentProfileImageUrl instanceof Object) {
      uploadingImage = true;
    }

    return (
      <div className='contact-form'>
        <div className='contact-form-top-elements'>
          <div className='contact-form-top'>
            <div className='padding-left padding-sm-right'>
              <div className='contact-form-top-row'>
                <DialogTitle style={{ padding: 0 }}>
                  <div className='contact-form-title margin-md-bottom margin-md-top'>
                    <div className='contact-header-name'>
                      {this.props.myUserAccount
                        ? <h4 className='title-text padding-xsm-right'>My User Account</h4>
                        : <h4 className='title-text padding-xsm-right'>Profile</h4>
                      }
                      {!this.props.myUserAccount && yogId
                        ? <div className='contact-card-yog-badge'>Yog</div>
                        : null
                      }
                      {
                        initialValues.id ? (
                          <CopyIcon content={initialValues.id} />
                        ) : null
                      }
                    </div>
                    <div>
                      {!this.props.myUserAccount && updatedAt
                        ? <div className='contact-form-updated'>(Updated: {updatedAt})</div>
                        : null
                      }
                    </div>
                  </div>
                </DialogTitle>
                <div className='contact-form-top-row-buttons'>
                  {!this.props.myUserAccount
                    ? <ContactCardMenu id='contactUsMenu' contact={initialValues} />
                    : null
                  }
                  <img
                    className='contact-form-dismiss'
                    onClick={this.props.closeModal}
                    src={icon_close}
                  />
                </div>
              </div>
              <BasicInfoSection
                myUserAccount={this.props.myUserAccount}
                initialValues={initialValues}
              />
            </div>
          </div>
          <div
            className='padding'>
            <PersonalInfoSection
              initialValues={initialValues}
              myUserAccount={this.props.myUserAccount}
            />
          </div>
        </div>
        <PanelFooter
          form={this.props.myUserAccount ? FORMS.USER_PROFILE_FORM : FORMS.CONTACT_INFO}
          uploadingImage={uploadingImage}
          isFormDirty={isFormDirty}
          isFormValid={isFormValid}
          isUnverified={isUnverified}
        />
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    initialValues: state.contactForm.clickedUser,
    selectedValues: formValueSelector(props.myUserAccount ? FORMS.USER_PROFILE_FORM : FORMS.CONTACT_INFO)(state, 'birthDate', 'addressDetails', 'profileImageUrl'),
    formSubmitted: state.contactForm.formSubmitted,
    isFormValid: isValid(props.myUserAccount ? FORMS.USER_PROFILE_FORM : FORMS.CONTACT_INFO)(state),
    isFormDirty: isDirty(props.myUserAccount ? FORMS.USER_PROFILE_FORM : FORMS.CONTACT_INFO)(state),
    searchValue: state.contactsData.searchValue,
  };
}

export default connect(
  mapStateToProps, {
  getContactsData: contactActions.fetchContactsList,
  getContactById: contactActions.fetchContactById,
  closeModal,
  destroy
}
)(ContactPanel);

ContactPanel.propTypes = {
  closeModal: PropTypes.func.isRequired,
  destroy: PropTypes.func.isRequired,
  formSubmitted: PropTypes.bool.isRequired,
  getContactsData: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  isFormDirty: PropTypes.bool.isRequired,
  isFormValid: PropTypes.bool.isRequired,
  myUserAccount: PropTypes.bool,
  searchValue: PropTypes.string.isRequired,
  selectedValues: PropTypes.object
};
