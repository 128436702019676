import React, { FC } from 'react';
import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { Chip } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { change } from 'redux-form';
import { FILTER_FORM } from '../../constants/forms';
import { selectIsReadOnlyAccess } from '../../reducers/authentication.reducer';
import { RootState } from '../../reducers/index.types';
import { contactActions } from './contacts.ducks';

import icon_close_mini from '../../assets/icons/icon_close_mini.svg';

type OptionType = {
  value: string;
  name: string;
};

type MaritalStatusType = {
  Married: boolean;
  Single: boolean;
};
type GenderType = 'Male' | 'Female';
type FollowType = {
  Unassigned: boolean;
};

export type FilterFormValuesType = {
  adminAttributes: OptionType[];
  attributes: OptionType[];
  emailType: string;
  followUp: FollowType;
  audiences: OptionType[];
  gender: GenderType;
  maritalStatus: MaritalStatusType;
  muktType: OptionType[];
};

export const FilterChips: FC = () => {
  const form = useSelector((state: RootState) => state.form);
  const filterFormValues: Record<string, MaritalStatusType | FollowType | OptionType[] | string> =
    form?.[FILTER_FORM]?.values ?? {};

  const chips: any[] = [];

  const { searchValue } = useSelector((state: RootState) => state.contactsData);

  const dispatch = useDispatch();
  //TODO check alternative to bindActionCreators
  const actions = bindActionCreators(contactActions, dispatch);
  const inviteToWorkshopFlag = useFeatureIsOn('sampark.invite-to-workshop');
  const isReadOnly = useSelector(selectIsReadOnlyAccess);

  const handleFilterOptionClick = (
    event: any,
    filterFormValues: FilterFormValuesType,
    arrayOption: OptionType | null
  ) => {
    const filterOption = event.currentTarget.getAttribute('data-parent')
      ? event.currentTarget.getAttribute('data-parent')
      : event.target.parentElement.getAttribute('data-parent');
    // We need to find the path / key on filterForm that we want to update
    if (filterFormValues && typeof filterFormValues === 'object') {
      const filterFormKeys = Object.keys(filterFormValues);
      if (filterFormKeys && filterFormKeys.length) {
        const foundKey = filterFormKeys.find((key) => filterOption.includes(key));
        if (foundKey && arrayOption) {
          const newValue = getFilterChipFormValues(filterFormValues, foundKey, arrayOption);
          dispatch(change(FILTER_FORM, foundKey, newValue));
        } else if (foundKey) {
          dispatch(change(FILTER_FORM, foundKey, ''));
        } else {
          console.error('Cannot find the Chip Key', event);
        }
      }
    }

    actions.fetchContactsList({ inviteToWorkshopFlag, isReadOnly, searchValue });
  };

  if (filterFormValues && typeof filterFormValues === 'object') {
    Object.entries(filterFormValues).map((filterGroupArray, index) => {
      if (filterGroupArray[1] && typeof filterGroupArray[1] === 'object') {
        Object.entries(filterGroupArray[1]).map((arrayValue, index) => {
          if (arrayValue[1] === true) {
            chips.push(
              <Chip
                data-parent={`${filterGroupArray[0]}.${arrayValue[0]}`}
                // Comments out because onDelete not working also need to work on styles
                onDelete={(e) =>
                  handleFilterOptionClick(e, filterFormValues as FilterFormValuesType, null)
                }
                key={`${index} ${arrayValue}`}
                onClick={(e) =>
                  handleFilterOptionClick(e, filterFormValues as FilterFormValuesType, null)
                }
                deleteIcon={<img style={{ height: 8, width: 8 }} src={icon_close_mini} />}
                className="chip"
                label={<span className="chip-label">{arrayValue[0].split('+').join(' ')}</span>}
              />
            );
          } else if (typeof arrayValue[1] === 'object') {
            chips.push(
              <Chip
                data-parent={`${filterGroupArray[0]}.${arrayValue[1]?.value}`}
                // Comments out because onDelete not working also need to work on styles
                onDelete={(e) =>
                  handleFilterOptionClick(
                    e,
                    filterFormValues as FilterFormValuesType,
                    arrayValue[1]
                  )
                }
                key={`${index} ${arrayValue[1]?.value}`}
                onClick={(e) =>
                  handleFilterOptionClick(
                    e,
                    filterFormValues as FilterFormValuesType,
                    arrayValue[1]
                  )
                }
                deleteIcon={<img style={{ height: 8, width: 8 }} src={icon_close_mini} />}
                className="chip"
                label={<span className="chip-label">{arrayValue[1]?.value}</span>}
              />
            );
          }
        });
      } else if (filterGroupArray[1] && typeof filterGroupArray[1] === 'string') {
        chips.push(
          <Chip
            data-parent={`${filterGroupArray[0]}.${filterGroupArray[1]}`}
            onDelete={(e) =>
              handleFilterOptionClick(e, filterFormValues as FilterFormValuesType, null)
            }
            key={index}
            onClick={(e) =>
              handleFilterOptionClick(e, filterFormValues as FilterFormValuesType, null)
            }
            deleteIcon={<img style={{ height: 8, width: 8 }} src={icon_close_mini} />}
            className="chip"
            label={<span className="chip-label">{filterGroupArray[1]}</span>}
          />
        );
        //TODO fix this Page for different use cases
      } else if (filterGroupArray[0] === 'unverified') {
        chips.push(
          <Chip
            data-parent={`${filterGroupArray[0]}`}
            onDelete={(e) =>
              handleFilterOptionClick(e, filterFormValues as FilterFormValuesType, null)
            }
            key={index}
            onClick={(e) =>
              handleFilterOptionClick(e, filterFormValues as FilterFormValuesType, null)
            }
            deleteIcon={<img style={{ height: 8, width: 8 }} src={icon_close_mini} />}
            className="chip"
            label={<span className="chip-label">{filterGroupArray[0]}</span>}
          />
        );
      }
    });
  }

  return <div className={'contacts-filters-container'}>{chips}</div>;
};

function getFilterChipFormValues(
  filterFormValues: FilterFormValuesType,
  foundKey: string,
  option: OptionType
): any {
  let optionArray: OptionType[] = [];
  switch (foundKey) {
    case 'muktType':
      optionArray = filterFormValues.muktType;
      break;
    case 'adminAttributes':
      optionArray = filterFormValues.adminAttributes;
      break;
    case 'attributes':
      optionArray = filterFormValues.attributes;
      break;
    case 'audiences':
      optionArray = filterFormValues.audiences;
      break;
    default:
      console.error('Unknown Filter Form Value, Please check ');
  }
  return optionArray.filter((optionArrayValue) => optionArrayValue.value !== option.value);
}
